import React, {useState} from "react";
import {useRealmApp} from "@pal/common";
import {
    AuthFlowFormContainer,
    AuthFlowInput,
    AuthWrapper,
    ErrorContainer,
    MainActionsWrapper,
    MyButton, MyLink
} from "./AuthComponents";
import styled from "@emotion/styled";
import {validateEmail} from "./LoginForm";
import {useHistory} from "react-router-dom";

export const ConfirmationBox = styled.div`
  display: flex;
  height: 40%;
  flex-flow: column;
  align-content: center;
  align-items: center;
  background: white;
  margin: auto;
  min-height: 400px;
  min-width: 280px;
  width: 80%;
  max-width: 630px;
  color: rgb(231, 129, 50);
  border: 1px solid rgb(255, 141, 53);
  box-shadow: 1px 0px 10px 1px rgb(255, 141, 53);
  font-size: 1.3em;
  h1 {
    color: white;
    background: rgb(255, 170, 105);
    width: 100%;
    text-align: center;
    height: 50%;
    margin: 0;
    padding-left: 20px;
    align-items: flex-end;
    display: flex;
  }

  p {
    padding: 20px;
    text-align: center;
    //font-family: "Roboto", sans-serif;
    
  }
`
const ForgotPassword = (props:any) => {
    const { sendResetPasswordEmail } = useRealmApp()

    const [email, setEmail] = useState("");
    const [sent, setSent] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const history = useHistory();

    const sendPasswordReset = async () => {
        if (!validateEmail(email)) {
            setError("Invalid email");
            return
        }
        const response = await sendResetPasswordEmail(email);
        setSent(true)
    }

    const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            sendPasswordReset()
        }
    }

    return !sent ? <AuthWrapper title={"Request password reset link"} gameApp={"true"}>
                {error && <ErrorContainer>{error}</ErrorContainer>}
                <AuthFlowInput id="email" onInputKeyDown={onInputKeyDown} onChange={(event) => setEmail(event.target.value)} label="email"  variant="filled" value={email} />

        <MainActionsWrapper>
            <div>
                <MyButton style={{width:"100%"}} variant="contained" onClick={sendPasswordReset}  >Send me the reset link</MyButton>
                <div style={{marginTop:"10px"}}>
                    <MyLink onClick={()=>history.push("/")}>Take me back to login page</MyLink>
                </div>
            </div>

        </MainActionsWrapper>

            </AuthWrapper> :<div style={{display:"flex", alignItems:"center", height:"100%", justifyContent:"center"}}>
        <ConfirmationBox>
            <h1>Please, check your email.</h1>
            <p>
                If an account is registered to <b>{email}</b>, <br/> you'll shortly receive an email with a link to reset your password.
                <br />
                If it's the wrong email, you can <MyLink onClick={()=>{setSent(false);setEmail("");setError("")}}>try again</MyLink>.
            </p>
        </ConfirmationBox>
    </div>
}

export default ForgotPassword;
