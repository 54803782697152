import React, {ReactElement, useEffect, useState} from "react";
import {useMongoDB, UserCustomDataDAL, useRealmApp} from "@pal/common";
import {useHistory, useParams} from "react-router-dom";
import {MyLink, ScreenWrapper} from "./AuthComponents";
import {LoadingProcess} from "./LoginForm";
import {useQuery} from "./ResetPassword";
import {ConfirmationBox} from "./ForgotPassword";

const ConfirmEmail = (props:any) => {
    const { confirmEmail } = useRealmApp()
    const { user } = useRealmApp();
    const { userDB } = useMongoDB();
    const authed = (user && user.state === "active");
    const [loading, setLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState<ReactElement | null>(null)
    const query = useQuery();
    const mailToken = query.get('eid');
    const confirmationToken = query.get('id');
    const history = useHistory();

    console.log(mailToken, " - ", confirmationToken)
    useEffect(  () => {
        const confirmEmailAsync = async (mailToken, confirmationToken) => {
            const response = await confirmEmail(mailToken, confirmationToken);
            if (response.status !== "success") {
                console.log("response", response);
                const message = (response.errorMessage) ? <>response.errorMessage </>: <>The token is invalid and we couldn't confirm email. <small><MyLink onClick={()=>{history.push("/")}}>Resend code?</MyLink></small></>
                setErrorMessage(message);
            }
            setLoading(false)
        }
        if (mailToken && confirmationToken) confirmEmailAsync(mailToken, confirmationToken)
    },[mailToken, confirmationToken])

    return <ScreenWrapper>
        {loading ? <LoadingProcess title={'Confirming associated token'}/>:
            <ConfirmationBox>
                {errorMessage ? <>
                        <h1>Confirmation failed</h1>
                        <p>{errorMessage}<br />
                            </p>

                        <MyLink onClick={()=>{history.push("/")}}>Back to learning!</MyLink>.
                        <span style={{padding:"20px", textAlign:"center"}}>Please <MyLink href={`mailto:hello@playalanguage.com`}>contact us</MyLink>, <br/> if you feel something is wrong.</span>

                    </>:
                    <>
                    <h1>Thank you!</h1>
                    <p>
                    Your email is successfully confirmed!
                    <br />
                    <MyLink onClick={()=>{history.push("/")}}>start learning!</MyLink>.
                    </p>
                    </>
                }
            </ConfirmationBox>
        }
    </ScreenWrapper>
}

export default ConfirmEmail;
