import BaseDAL from "./BaseDAL";
import {Asset, AssetType} from "@pal/common";
import {getDBInstance} from "@pal/common";



export class BaseAssetDAL extends BaseDAL<Asset> {

    fetchAllAssetsType = async (assetType: AssetType, filter:string ="") => {
        const db = getDBInstance();

        if (db) {
            const collection = db.collection(this.collectionName);
            const filterObject = (filter!=="") ? {assetType: assetType, key:{$regex : filter, '$options' : 'i'}} : {assetType: assetType};
            const responseArray:Asset[] = await collection.find(filterObject);

            responseArray.map((t)=>t._id = t._id?.toString()); //map all _id to string for redux.
            return responseArray;
        } else {
            console.log("Undefined DB")
        }
        let empty: Asset[] = [];
        return empty;
    }

}
