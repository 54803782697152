import {getUrlFromAsset, LanguageLevel, LearningLanguage, Quest} from "@pal/common";
import React, {useMemo, useState} from "react";
import {keyframes} from "@emotion/react";

import {
  Avatar,
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  styled,
  Switch,
  ToggleButtonGroup
} from "@mui/material";
import ToggleButton from "@mui/material/ToggleButton";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {useQuestService} from "../../services/QuestService";
import QuestCard from "./QuestCard";

import {bounce, merge, tada} from "react-animations";
import {useUserDataService} from "../../services/UserDataService";
import {useAppSelector} from "../../app/hooks";
import {getPlayingLocation} from "../../slices/userGamePlaySlice";
import { convertLanguageNameToLanguageCode } from "screens/GameLocationStage";
import {useTranslation} from "react-i18next";

const mergeAnimation = merge(tada, bounce);
const newBadgeAnimation = keyframes`${mergeAnimation}`;


const QuestManagerWrapper = styled(Box)<{ expandedMode: boolean }>(({ theme, expandedMode }) => ({
  //position: "absolute",
  //top: !expandedMode ? 'calc(100% - 150px)' : 'calc(100% - 55vh - 120px)',
  width: "98%",
  maxWidth: "600px",
  borderRadius: "20px",
  height: !expandedMode ? '40px' : '55vh',
  transition: "all 0.2s ease-in-out",
  transform: !expandedMode ? "scale(0.8)" : "scale(1)",
  marginBottom:"20px",
  pointerEvents: "all",
}));

const QuestManagerDisplay = styled(Box)<{ expandedMode: boolean }>(({ theme, expandedMode }) => ({
  background: "linear-gradient(180deg, #4f42886e 70%, rgba(59, 30, 77, 0.75) 100%)",
  clipPath: "polygon(0px -1px, 99.2% 1.6%, 95.50% 97%, 4% 99%)",
  width:"100%",
  height:"100%",
}));

const DisplayHeader = styled(Box)<{ expandedMode: boolean, headerButton?:boolean }>(({ theme, expandedMode, headerButton}) => ({
  width:"100%",
  margin:"auto",
  display:"flex",
  justifyContent: expandedMode ? "left" : "center",
  paddingLeft: expandedMode ? "35px": "10px",
  background: headerButton ? theme.palette.primary.main : "#f09116",//"#d07500", //TODO use darken(theme.palette.secondary.main) ?
  //borderRadius:"20px",
  clipPath:"polygon(0 0, 100% 0, 100% 100%, 0 90%)",
  pr:"10px",
}));

//Temporary quest processor, it should be moved to the server sometime
export const QuestProcessor = (quests: Quest[]) => {
  return quests;
}


const ActionsBar = () => {
  return<FormControl sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
    <FormControlLabel
        labelPlacement="top"
        sx={{
          [`& .MuiTypography-root`]: {
            fontSize: "0.8em",
          },
        }}
        control={<Switch
            checked={true}
            onChange={()=>{}}
            size="small"
            color="primary"
        />} label="Map Hints"
    />

    <FormControlLabel
        labelPlacement="top"
        sx={{
          [`& .MuiTypography-root`]: {
            fontSize: "0.8em",
          },
        }}
        control={<Switch
            checked={true}
            onChange={()=>{}}
            size="small"
            color="primary"
        />} label="List unpublished" />

  </FormControl>
}

export const NewBadge = styled(Chip)`
  position: absolute;
  right:-30px;
  top:-20px;
  background: gold;
  clip-path: polygon(6px 14px, 89.64% 4px, 73.51% 84.64%, 23px 91.91%);
  border-radius: 0px;
  font-weight: bold;
  font-size: 1em;
  padding: 10px;
  height: auto;
  animation: 2s ${newBadgeAnimation};
`

const QuestManager = (props:{onQuestWidgetClick?:()=>void}) => {
  const [current, setCurrent] = useState<boolean>(true);
  const [expanded, setExpanded] = useState<boolean>(false);
  const questService = useQuestService();
  const openCityQuests = questService.getOpenCityQuests();
  const newQuestsIds = questService.getNewCityQuests();
  const activeQuestInCity = questService.getActiveQuestInCity();
  const completedQuests = questService.getCompletedQuests();
  const activeQID = activeQuestInCity?.qid || "";

  const playingLocation = useAppSelector(getPlayingLocation);
  const playingLanguage: LearningLanguage = convertLanguageNameToLanguageCode(playingLocation?.city.language) || 'es';
  const userDataService = useUserDataService();
  const userLanguageGPData =
      userDataService.getLanguageGameplayData(playingLanguage);
  const languageLevel:LanguageLevel = userLanguageGPData?.languageLevel || 'intermediate';
  const { t } = useTranslation();

  const onToggleOpen = () => {
    if (!expanded) {
      setExpanded(true);
    } else {
      setExpanded(false);
      setCurrent(true);
    }
  }

  const getTitle = () => {
    if (!expanded) {
      return (activeQuestInCity) ? activeQuestInCity.name : t("questM.noActiveQuest");
    } else {
      return (current ? t("questM.openQuests") : t("questM.completedQuests"));
    }
  }

  const onClickContainer = () => {
    if (props.onQuestWidgetClick) {
      props.onQuestWidgetClick();
    }
    else {
      !expanded && onToggleOpen()
    }
  }

  const newQuestsAvailable = newQuestsIds?.filter((q)=>!q.viewed).length ? true : false;
  const isDisplayHeaderButton = (props.onQuestWidgetClick) ? true: false;
  const questsList = (current) ? openCityQuests : completedQuests;
  const questAvatar = (activeQuestInCity?.giver?.portrait) ? getUrlFromAsset(activeQuestInCity.giver.portrait) : "https://lq-gc-graphics.s3.amazonaws.com/antonio__AT4.png"

  return<QuestManagerWrapper expandedMode={expanded} >

    <QuestManagerDisplay expandedMode={expanded} sx={{}} onClick={()=>onClickContainer()}>

        <DisplayHeader expandedMode={expanded} headerButton={isDisplayHeaderButton}>
          {(!expanded && activeQuestInCity) && <React.Fragment>
              <h2 style={{margin:"0", color:"#fff", paddingLeft:"5px"}}>{t("questM.next")}:</h2>
              <Avatar alt="Quest giver" sx={{width: "30px", height: "30px", background:"#90ecd2", margin:"auto 15px", marginRight:"10px" }} src={questAvatar} />
          </React.Fragment>}
          <h2 style={{margin:"0", overflow:"hidden", marginRight: "10px", color:"#fff", textOverflow: "ellipsis", whiteSpace: "nowrap", maxWidth:"fit-content", flex:"1 1 auto"}}>{getTitle() || t("questM.noActiveQuest")}</h2>
          {expanded && <IconButton sx={{position:"absolute", right:"5px", [`& svg`]:{ color:"white"}}} aria-label="close" onClick={()=>onToggleOpen()}>
            <HighlightOffIcon />
          </IconButton>}

        </DisplayHeader>
      <Box id={"QuestContainer"} sx={{overflow:"scroll", height:'calc(100% - 120px)', my:1, overflowX:"hidden",}}>
        {questsList && questsList.map((quest)=>{
          const isNewQuest = newQuestsIds?.find((nq)=>!nq.viewed && nq.qid === quest.qid) ? true : false;
          const active = (quest.qid === activeQID)
          return <QuestCard key={quest.qid} isNewQuest={isNewQuest} languageLevel={languageLevel} activeQuest={active} quest={quest} completed={!current} />
        })}
      </Box>
      {expanded && <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", paddingTop:"10px"}}>
      <ToggleButtonGroup
          color="primary"
          value={current ? "current" : "completed"}
          exclusive
          onChange={()=>{}}
          aria-label="Quest views"
          sx={{background:"#e6e4ff", height:"30px", borderRadius:"30px", overflow:"hidden"}}
      >
        <ToggleButton value="current" onClick={()=>setCurrent(true)} sx={{width:"150px"}}>{t("questM.current")}</ToggleButton>
        <ToggleButton value="completed" onClick={()=>setCurrent(false)} sx={{width:"150px"}} >{t("questM.completed")}</ToggleButton>
      </ToggleButtonGroup>
      </Box>}
    </QuestManagerDisplay>
    {!expanded && newQuestsAvailable && <NewBadge label={t("questM.new")} />}
  </QuestManagerWrapper>
}

export default QuestManager;
