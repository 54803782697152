import {getGenderMessage, getLanguageText} from "../../../screens/GameLocationStage";
import { StageNode } from "../../../model/StageScript";
import {
  getLanguagePuzzleData,
  PuzzleTitleBadge,
  PuzzleWrapper,
} from "./InputPuzzle";
import { PlayerTextOption } from "../PlayerInputArea";
import checkIcon from "@mui/icons-material/Check";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import { fadeIn, fadeOut } from "react-animations";
import { keyframes } from "@emotion/react";
import { IconButton } from "@mui/material";
import { useUserDataService } from "../../../services/UserDataService";
import { LanguageLevel } from "@pal/common";
import {ButtonIcon} from "../../layout/ButtonIcon";
import {HintText} from "../MessageDisplay";
import Button from "@mui/material/Button";
import {useTranslation} from "react-i18next";

const fadeInAnimation = keyframes`${fadeIn}`;
const fadeOutAnimation = keyframes`${fadeOut}`;

const CheckIcon = styled(checkIcon)`
  color: rgb(235, 250, 221);
`;

const OptionsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
`;

const PuzzleHeader = styled.h1`
  color: white;
  padding: 10px 20px;
  margin: 12px auto;
  width: fit-content;
`;
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }
};

const RightAnswerFeedback = (props: {
  answer;
  completeText;
  hint?;
  showFeedback?;
  forwardCallback?;
}) => {
  const { answer, completeText, hint, showFeedback, forwardCallback } = props;
  const { t } = useTranslation();
  const RightAnswerContainer = styled.div<{ showFeedback?: boolean }>`
    position: absolute;
    transition: all 1s ease-in-out;
    top: calc(0% - 76px);
    width: 100%;
    min-height: 80px;
    height: fit-content;
    padding: 5px 10px;
    right: 0;
    background: rgb(141, 175, 112);
    background: linear-gradient(90deg,
    rgba(255, 255, 255, 0) 0%,
    rgb(129, 170, 94) 32%,
    rgb(129, 170, 94) 64%,
    rgba(255, 255, 255, 0) 100%);
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    opacity: ${(props) => (props.showFeedback ? 1 : 0)};
    animation: 0.8s ${(props) => (props.showFeedback ? fadeInAnimation : fadeOutAnimation)};
    flex-flow: column;

    @media (max-width: 768px) {
      background: rgb(141, 175, 112);
    }

    span {
      color: rgb(235, 250, 221);
      font-weight: 500;
      font-size: 1.5em;
      margin-right: 5px;
    }
  `;
  const ForwardButton = styled(Button)`
    padding: 2px;
    color: rgb(235, 250, 221);
    border: 1px solid rgb(235, 250, 221);
  `;
  const splitPhrase = completeText.split(answer);
  const prefix = splitPhrase.length ? splitPhrase[0] : "";
  const suffix = splitPhrase.length ? splitPhrase[1] : "";
  return (
    <RightAnswerContainer showFeedback={showFeedback}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          {prefix}
          <strong>{answer}</strong>
          {suffix}
        </span>{" "}
        <CheckIcon />
      </div>
    <div>
        <ForwardButton onClick={() => forwardCallback()}>
          {t("puzzle.advanceNext")}
        </ForwardButton>
    </div>
      <div style={{ padding: "5px", fontSize: "0.95em" }}>{hint}</div>
    </RightAnswerContainer>
  );
};

const HintButtonIcon = styled(ButtonIcon)`
  position: absolute;
  top: 22px;
  left: calc(50% - 25px);
  writing-mode: vertical-lr;
  text-orientation: upright;
  z-index: 1;
  border-radius: 100% !important;
  //box-shadow: 2px 4px 4px rgb(222, 178, 0);
  width: 40px;
  height: 40px;
  font-size: 1.8em;
  //clip-path: polygon(50% 0, 100% 0, 100% 100%, 0% 100%);
  //padding-left: 18px;
  //background: #d8b7de;
  //color: #1f0068;

  &:hover {
    //background: #f1d6ff;
  }
`

const MultipleChoicePuzzle = (props: {
  languageLevel: LanguageLevel;
  stageNode: StageNode;
  style: any;
  onRightAnswer: () => void;
  onWrongAnswer: () => void;
}) => {
  const { stageNode, onRightAnswer, onWrongAnswer, style, languageLevel } =
    props;

  const [hintText, setHintText] = useState<string>("");

  const userDataService = useUserDataService();
  const userCustomData = userDataService.getCustomData();
  const puzzleStringMarker = "______________";
  const [disabledAlternatives, setDisabledAlternatives] = useState<string[]>(
    []
  );
  const [shuffledAlternatives, setShuffledAlternatives] = useState<string[]>(
    []
  );
  const [showRightAnswerFeedback, setShowRightAnswerFeedback] =
    useState<boolean>(false);
  const { t } = useTranslation();

  /*useEffect(() => {
    if (showRightAnswerFeedback) {
      let timer = setTimeout(() => {
        onRightAnswer();
      }, 8000);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [showRightAnswerFeedback]);*/

  const lt = getLanguageText(
      languageLevel,
      stageNode.questNode.data
  );
  const puzzleHint = lt?.hint || undefined;
  const completeText = getGenderMessage(lt, userCustomData?.gender);
  const puzzleData = getLanguagePuzzleData(
    languageLevel,
    stageNode.questNode.data?.puzzleData
  );
  const replaceWord = puzzleData.replaceString;
  const completeTextBuffer = completeText;

  let puzzleReplaceText = (replaceWord.startsWith(' ')) ? ' ' + puzzleStringMarker : puzzleStringMarker
  puzzleReplaceText = (replaceWord.endsWith(' ')) ? puzzleReplaceText + ' ' : puzzleReplaceText

  const shownText = completeTextBuffer?.replace(replaceWord, puzzleReplaceText);
  const alternatives = puzzleData.options?.split(",") || ['probably', 'empty', 'options'] ;

  if (shuffledAlternatives.length === 0) {
    shuffleArray(alternatives);
    setShuffledAlternatives(alternatives);
  }

  function checkAnswer(textOption: string) {
    const testText = shownText!.replace(puzzleStringMarker, textOption);
    if (completeText === testText) {
      //onRightAnswer();
      const el = document.getElementById("app");
      // @ts-ignore
      el.scrollTo({ top: 0, behavior: "smooth" });
      setShowRightAnswerFeedback(true);
    } else {
      onWrongAnswer();
      disabledAlternatives.push(textOption);
      setDisabledAlternatives([...disabledAlternatives]);
      console.log(
        "disabledAlternatives",
        disabledAlternatives.includes(textOption)
      );
    }
  }

  return (
    <PuzzleWrapper>
      {puzzleHint && !showRightAnswerFeedback && <HintButtonIcon variant="contained" onClick={() => hintText === '' ? setHintText(puzzleHint || "Sorry, there's no hint this time.") : setHintText('')} sx={{border:puzzleData.hint ? '3px #ff841b solid' :'0px' }}>
        ?
      </HintButtonIcon>}
      {hintText && !showRightAnswerFeedback && <HintText>{hintText}</HintText>}
      <PuzzleTitleBadge>{t("puzzle.chooseTheRight")}!</PuzzleTitleBadge>
      <PuzzleHeader>{!showRightAnswerFeedback ? shownText : "👌"}</PuzzleHeader>

      <OptionsWrapper>
        {shuffledAlternatives.map((a) => (
          <PlayerTextOption
            disabled={disabledAlternatives.includes(a)}
            style={style}
            key={"po_" + a}
            onClick={() => checkAnswer(a)}
          >
            {a}
          </PlayerTextOption>
        ))}
        {showRightAnswerFeedback && (
          <RightAnswerFeedback
            answer={puzzleData.replaceString}
            hint={puzzleHint}
            completeText={completeText}
            showFeedback={showRightAnswerFeedback}
            forwardCallback={onRightAnswer}
          />
        )}
      </OptionsWrapper>
    </PuzzleWrapper>
  );
};

export default MultipleChoicePuzzle;
