import React, {useContext, useEffect, useState} from "react"

import {useRealmApp} from "./realm"

const MongoDBContext = React.createContext(null)

// @ts-ignore
let _db = null;
export const getDBInstance = () => {
    // @ts-ignore
    return _db;
}

// @ts-ignore
let _userDb = null;
export const getUserDBInstance = () => {
    // @ts-ignore
    return _userDb;
}

// @ts-ignore
export const MongoDB = ({ children }) => {

    // @ts-ignore
    const { user } = useRealmApp();
    const [db, setDB] = useState();
    const [userDB, setUserDB] = useState();
    useEffect(() => {
        // @ts-ignore
        if (user !== null && _db === null) {
            const realmService = user.mongoClient(
                process.env.REACT_APP_REALM_SERVICE_NAME
            )
            _db = realmService.db(process.env.REACT_APP_DB_NAME)
            setDB(_db);

            _userDb = realmService.db(process.env.REACT_APP_USER_DB_NAME)
            console.log("_userDb", _userDb);
            setUserDB(_userDb);
        }
    }, [user])

    return (

        <MongoDBContext.Provider
            // @ts-ignore
            value={{
                db,
                userDB
            }}
        >
            {children}
        </MongoDBContext.Provider>
    )
}

export const useMongoDB = () => {
    const mdbContext = useContext(MongoDBContext)
    if (mdbContext == null) {
        throw new Error("useMongoDB() called outside of a Mongodb?")
    }
    return mdbContext
}

//export default Mongodb
