import styled from "@emotion/styled";
import { useEffect, useMemo, useState } from "react";
import {BaseDAL, City, MediaHelper, ProdQuestDAL, QuestDAL, useMongoDB} from "@pal/common";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import { useHistory } from "react-router-dom";
import {getPlayingCity, setPlayingCity} from "slices/userGamePlaySlice";
import { fadeInUp } from "react-animations";
import { keyframes } from "@emotion/react";
import NavigationBar from "../components/layout/NavigationBar";
import {Box} from "@mui/material";
import {useQuestService} from "../services/QuestService";
import { useTranslation } from 'react-i18next';
import {setBgAudioSrc} from "../slices/userGamePlaySlice";

const titleAnimation = keyframes`${fadeInUp}`;

const Wrapper = styled.div`
  min-height: 100%;
  height: fit-content;
  width: 100%;
  background: rgb(255, 206, 110);
  background: linear-gradient(
    0deg,
    rgb(255, 223, 163) 0%,
    rgba(255, 255, 255, 0) 75%
  );
  display: flex;
  flex-flow: column;
  padding-top: 100px;
`;

const CityList = styled.ul`
  list-style: none;
  padding: 20px 10px;
  margin: 0px;
  flex: 1 1 auto;
  
`;

const ListTitle = styled.h1`
  max-width: 400px;
  margin: auto;
  padding-left: 20px;
  color: ${(props) => props.theme.palette.secondary.main};
  animation: 1s ${titleAnimation};
`;

const CityCard = styled.li<{ bgUrl: string }>`
  background-image: url(${(props) => props.bgUrl});
  background-size: cover;
  background-position: center center;
  width: 100%;
  margin: 0px auto 20px auto;
  max-width: 400px;
  height: 120px;
  background-color: #996ee7;
  display: flex;
  justify-content: flex-end;
  flex-flow: column;

  overflow: hidden;
  border-radius: 10px;
  box-shadow: 0px 7px 9px 5px rgba(49, 24, 86, 0.75);

  div {
    background-color: #fca345;
    color: #3d2975;
    padding: 0px 5px;
    clip-path: polygon(0 60%, 100% 0, 100% 100%, 0% 100%);
    display: flex;
    align-items: flex-end;
    height: 25%;

    span {
      width: 100%;
      text-align: right;
      color: #ffffff;
      font-weight: bold;
      font-family: "Saira Condensed";
      font-size: 1.1em;
      height: fit-content;
    }
  }

  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 7px 20px 5px rgba(49, 24, 86, 0.75);
    transform: scale(1.05);

    div {
    }

    span {
      color: #280470;
      color: white;
    }
  }
`;
const CityListContainer = styled(Box)`
`

export const getLanguageNameFromProp = (language) => {
  switch (language.toLowerCase()) {
    case "spanish":
      return "Español";
    case "german":
      return "Deutsch"
    case "portuguese":
      return "Português";
    case "english":
      return "English";
    case "french":
      return "François";
    default:
      return ""
  }
}

const GlobeCities = () => {
  const { db } = useMongoDB();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const lastPlayingCity = useAppSelector(getPlayingCity);

  const citiesDAL = useMemo(() => new BaseDAL<City>("cities"), []);
  const [cities, setCities] = useState<City[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    async function wrapFetch() {
      const cities = (process.env.REACT_APP_PAL_ENV! && process.env.REACT_APP_PAL_ENV === "production") ? await citiesDAL.fetchWithFilterObject({ "name": { "$in": ["Barcelona"/*, "London"*/] }}) : await citiesDAL.fetch();
      setCities(cities);
    }
    wrapFetch();
  }, [db]);

  const pushToCityMap = (city: City) => {
    dispatch(setPlayingCity(city));
    if(city.defaultBgMusic){
      dispatch(setBgAudioSrc(MediaHelper.getUrlFromAsset(city.defaultBgMusic)));
    }
    history.push("/city/" + city?.cityCode);
  };

  const onQuestWidgetClick = () => {
    if (lastPlayingCity) {
      history.push("/city/" + lastPlayingCity.cityCode);
    }
  }

  return (
    <Wrapper>
      <ListTitle>{t("home.chooseCity")}</ListTitle>
      <CityList>
        <CityListContainer>
        {cities.map((city: City, index: number) => {
          const coverUrl = MediaHelper.getUrlFromAsset(city.cover);

          const cityLanguage = getLanguageNameFromProp(city.language.toString())
          return (
            <CityCard
              bgUrl={coverUrl}
              key={index}
              onClick={() => pushToCityMap(city)}
            >
              <div>
                <span>
                  {city.name} - {cityLanguage}
                </span>
              </div>
            </CityCard>
          );
        })}
        </CityListContainer>
      </CityList>
      <NavigationBar onQuestWidgetClick={onQuestWidgetClick} />
    </Wrapper>
  );
};

export default GlobeCities;
