import {Avatar, Box, Chip, styled} from "@mui/material";
import ActivatedQuestIcon from "@mui/icons-material/Star";
import DeactivatedQuestIcon from "@mui/icons-material/StarBorder";
import Button from "@mui/material/Button";
import {replayQuest, setActiveQuestInCity} from "../../slices/userGamePlaySlice";
import React, {useState} from "react";
import {getUrlFromAsset, LanguageLevel, Quest} from "@pal/common";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {getPlayingCity} from "../../slices/userGamePlaySlice";
import {NewBadge} from "./QuestManager";
import {useQuestService} from "../../services/QuestService";
import {useTranslation} from "react-i18next";


const QuestCardContainer = styled(Box)<{ expandedMode: boolean }>(({ theme, expandedMode }) => ({
  position:"relative",
  background: "linear-gradient(180deg, #eed8cacc 70%, rgba(255, 255, 255, 0.45) 100%)",
  paddingLeft: "16px",
  minHeight: "60px",
  marginLeft: "9%",
  marginTop:"20px",
  marginRight:"auto",
  overflow: "visible",
  display: "flex",
  flexFlow: "row",
  width: "84%",
  borderBottomLeftRadius: "30px",
  transition: "all 0.2s ease-in-out",
  ['&:hover']: {
    transform:"scale(1.05)"
  },
  h1: {
    margin:"0",
    color:"#fff",
    fontSize: "1.25em",
  },
  h2: {
    margin:"5px 0px",
    fontSize: "0.9em",
    zIndex: "2",
  }
}));

const QuestCardSlicer = styled(Box)`
  position:absolute;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg,
  rgba(239, 161, 38, 0.8) 70%,
  rgb(255, 168, 0) 100%);
  color: #fff;
  display: flex;
  flex-flow: row;
  clip-path: polygon(0px 0px, 100% 0px, 66.54% 145.50%, -18px 167%);
  z-index: 0;
  border-bottom-left-radius: 30px;
`;

const QuestCard = (props:{isNewQuest:boolean, activeQuest:boolean, languageLevel?:LanguageLevel, completed?:boolean, quest:Quest}) => {
    const playingCity = useAppSelector(getPlayingCity);
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState<boolean>(false);

    const {quest} = props;
    const activatedIcon = props.activeQuest ? <ActivatedQuestIcon sx={{color:'yellow'}} /> : <DeactivatedQuestIcon />;
    const questService = useQuestService();
    const { t } = useTranslation();

    const setQuestAsViewed = () =>{
        if(props.isNewQuest) {
            questService.setViewedQuest(quest.qid);
        }
    }
    const onCardClick = () => {
        setExpanded(!expanded)
        setQuestAsViewed();
    }

    const onSetActive = () => {
        dispatch(setActiveQuestInCity({qid:quest.qid, cityCode: playingCity?.cityCode||''}))
        setQuestAsViewed();
    }

    const onQuestButtonClick = () => {
        if (props.completed){
            dispatch(replayQuest(quest.qid));
        } else {
            onSetActive();
        }
    }

    const MainButton = () => {
        const buttonTitle = (props.completed) ? t("questM.replay") : (!props.completed && props.activeQuest) ? t("questM.active") : t("questM.activate");
        return <Button sx={{ clipPath: "polygon(11px -1px, 100% 0px, 90.14% 98.92%, -1px 98.51%);"}}
                       variant={"contained"}
                       startIcon={!props.completed && activatedIcon}
                       onClick={(e)=>{e.stopPropagation(); onQuestButtonClick()}} ><span style={{fontSize:"0.8em"}}>{buttonTitle}</span></Button>
    }
    const questAvatar = (quest.giver?.portrait) ? getUrlFromAsset(quest.giver?.portrait) : "https://lq-gc-graphics.s3.amazonaws.com/antonio__AT4.png"

    const getSecondaryCategories = () => {
        switch (props.languageLevel) {
            case 'beginner':
                return quest.languageSecondaryCategoriesBeginner || []
            case 'intermediate':
                return quest.languageSecondaryCategoriesIntermediate || []
            case 'advanced':
                return quest.languageSecondaryCategoriesAdvanced || []
        }
        return []
    }

    const mergedLanguageCategories = quest.languageCategories?.concat(getSecondaryCategories()) || [];
    return <QuestCardContainer key={quest.qid} expandedMode={expanded} onClick={()=>onCardClick()}>
        <QuestCardSlicer />
        <Box sx={{verticalAlign:"center", position:"absolute", top: "-15px", left:"-25px", display: 'flex', alignItems: 'center', justifyContent:"center"}}>
            <Avatar alt="Quest giver" sx={{width: "50px", height: "50px", maxWidth:"60px", maxHeight:"60px", background:"#90ecd2" }} src={questAvatar} />
        </Box>
        <Box sx={{zIndex:2, width:"100%"}}>
            <Box sx={{display:"flex"}}>
            <Box sx={{flex:"1 1 auto", pl:2, alignItems: 'center'}}>
                <h1>{quest.name} {props.isNewQuest && !props.completed && <NewBadge label={t("questM.new")}  sx={{background:"gold"}}/>}</h1>
                <h2>{quest.logline}</h2>
                {expanded && <Box>
                    <p style={{width:"100%"}}>{quest.description}</p>
                </Box>}
            </Box>
            <Box sx={{display: 'flex', alignItems: 'baseline', justifyContent:"center", paddingTop:"32px", paddingRight:"3px"}}>
                <MainButton />
            </Box>
            </Box>
            <Box>
                {quest.storyCategories?.map((cat, index)=> <Chip key={`tagSC-${index}`} sx={{background:"#ffe4d0", m:0.5, height:25}} label={cat} />)}
                {mergedLanguageCategories?.map((cat, index)=> <Chip key={`tagLC-${index}`} sx={{background:"#f5e189", m:0.5, height:25}} label={cat} />)}
                {expanded && <Box>
                    <Button sx={{pl:2}} onClick={(e)=>{e.stopPropagation();}}>{t("questM.reviewGrammar")}</Button>
                </Box>}
            </Box>
        </Box>
    </QuestCardContainer>
}

export default QuestCard;
