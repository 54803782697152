import {Quest} from "../model/Quest";
import BaseDAL from "./BaseDAL";
import {getDBInstance} from "../providers/mongodb";


const collectionName = "quests";
const prodCollectionName = "_prod-quests";

export class BaseQuestDAL extends BaseDAL<Quest> {

    //TODO implement with agreggation
    fetchPublishedQuests = async (filter:string="", filterField:string="name") => {
        const db = getDBInstance();

        if (db) {
            const collection = db.collection(this.collectionName);
            //const filterObject = (filter!=="") ? {key:{$regex : filter, '$options' : 'i'}} : {assetType: assetType};
            //let filterObject: {[key: string]: { $regex: string; $options: string; }} = {};
            let filterObject:any = {};
            filterObject[filterField] = { $regex : filter, '$options' : 'i' };
            console.dir(filterObject)
            const responseArray:Quest[] = await collection.find((filter!=="") ? filterObject : {});

            const preparedQuests = responseArray.map((quest)=> {
                quest._id = quest._id?.toString() //map all _id to string for redux.
                // @ts-ignore
                quest.nodes = quest.elements?.filter(element => element.type!==undefined) || [];
                // @ts-ignore
                quest.edges = quest.elements?.filter(element => element.type===undefined) || [];
                return quest;
            });
            return preparedQuests;
        } else {
            console.log("Undefined DB")
        }
        let empty: Quest[] = [];
        return empty;
    }

}

export const ProdQuestDAL = new BaseQuestDAL(prodCollectionName);
export default new BaseQuestDAL(collectionName);
