import {Route} from "react-router-dom";
import LoginForm from "./LoginForm";

const PrivateRoute = (props:any) => {
    const {children, authed, signUpCallback, enableSignUp, ...rest} = props
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <>{children}</>
                : <LoginForm signUpCallback={signUpCallback} enableSignUp={enableSignUp} />}
        />
    )
}

export default PrivateRoute
