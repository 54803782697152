import { createTheme } from "@mui/material";
import { SimplePaletteColorOptions } from "@mui/material/styles/createPalette";

declare module "@mui/material" {

}

declare module "@emotion/react" {
  export interface Theme {
    palette: {
      primary: SimplePaletteColorOptions;
      secondary: SimplePaletteColorOptions;
      error: SimplePaletteColorOptions;
      warning: SimplePaletteColorOptions;
      info: SimplePaletteColorOptions;
      success: SimplePaletteColorOptions;
      languageElementColor: string;
    };
  }
}

export const gameTheme = createTheme({
  //borderRadius: "20px",
  palette: {
    primary: {
      main: "#481579",
    },
    secondary: {
      main: "#ef8331",
    },
    error: {
      main: "#994e4e",
    },
    warning: {
      main: "#f1e065",
    },
    info: {
      main: "#77e3e0",
    },
    success: {
      main: "#b5ff9f",
    },
    //languageElementColor: "#b5ff9f",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontFamily: "Saira Condensed",
          height: "100%",
        },
      },
    },
  },
});
