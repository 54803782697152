import styled from "@emotion/styled";
import {FormControl, InputLabel, Link, OutlinedInput} from "@mui/material";
import React from "react";
import {Button} from "@mui/material";
import {MyInputProps} from "./LoginForm";

export const AuthFlowFormContainer = styled.div<{ primaryColor?: string, secondaryColor?: string }>`
  width: 95%;
  max-width: 400px;
  margin: auto;
  border: 1px solid ${props => (props.secondaryColor) ? props.secondaryColor : "#481579"};
  box-shadow: 1px 5px 10px #934f2a;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  background: #ffffff;

  h3 {
    background: ${props => (props.primaryColor) ? props.primaryColor : "#481579"};
    color: white;
    padding: 20px;
    margin: 0;
    height: 100px;
  }
`
export const ScreenWrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  width: 100%;
`

export const MyButton = styled(Button)`
  background: ${props => (props.theme.palette) ? props.theme.palette.primary.main : "#481579"} !important;
  color: #fff !important;
  margin-top: 20px;
  width: 100px;
  margin: auto !important;
  border-radius: 10px !important;

  span {
    color: white !important;
  }
`
export const MyLink = styled(Link)`
  cursor: pointer;
`
export const ErrorContainer = styled.div`
  width: 60%;
  margin: 10px 20%;
  background: rgba(255, 82, 82, 0.33);
  padding: 5px;
  border-radius: 10px;
`
export const MainActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 20px 20%;
  align-content: center;
  align-items: center;
`
export const SvgShape = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);

  & svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
  }

  & svg path {
    fill: #fff;
  }
`
export const AuthFlowInput = (props: MyInputProps) => {
    const {id, label, onChange, value} = props;
    return <FormControl sx={{width: '90%', margin: "14px auto", color: "#000"}} variant="outlined">
        <OutlinedInput id={id}
                       onChange={onChange}
                       sx={{background: "#fff",
                           '& .MuiInputBase-input': {
                               textTransform: 'none'
                           }
                       }}
                       label={label}
                       inputProps={{
                           autoCapitalize: 'none'
                       }}
                       value={value}/>
        <InputLabel htmlFor={id}>{label}</InputLabel>
    </FormControl>
}

export const AuthWrapper = (props:any) => {
    const {children, gameApp, title } = props;
    const primaryColor = gameApp ? "#ef8331": "#481579";
    const secondaryColor = gameApp ? "#ef8331": "#481579";
    return <ScreenWrapper>
        <AuthFlowFormContainer primaryColor={primaryColor} secondaryColor={secondaryColor}>

            <div style={{position: "relative"}}>
                <h3>{title}</h3>
                <SvgShape>
                    <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                         preserveAspectRatio="none">
                        <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120"
                             preserveAspectRatio="none">
                            <path
                                d="M0,0V46.29c47.79,22.2,103.59,32.17,158,28,70.36-5.37,136.33-33.31,206.8-37.5C438.64,32.43,512.34,53.67,583,72.05c69.27,18,138.3,24.88,209.4,13.08,36.15-6,69.85-17.84,104.45-29.34C989.49,25,1113-14.29,1200,52.47V0Z"
                                opacity=".25"></path>
                            <path
                                d="M0,0V15.81C13,36.92,27.64,56.86,47.69,72.05,99.41,111.27,165,111,224.58,91.58c31.15-10.15,60.09-26.07,89.67-39.8,40.92-19,84.73-46,130.83-49.67,36.26-2.85,70.9,9.42,98.6,31.56,31.77,25.39,62.32,62,103.63,73,40.44,10.79,81.35-6.69,119.13-24.28s75.16-39,116.92-43.05c59.73-5.85,113.28,22.88,168.9,38.84,30.2,8.66,59,6.17,87.09-7.5,22.43-10.89,48-26.93,60.65-49.24V0Z"
                                opacity=".5"></path>
                            <path
                                d="M0,0V5.63C149.93,59,314.09,71.32,475.83,42.57c43-7.64,84.23-20.12,127.61-26.46,59-8.63,112.48,12.24,165.56,35.4C827.93,77.22,886,95.24,951.2,90c86.53-7,172.46-45.71,248.8-84.81V0Z"
                            ></path>
                        </svg>
                    </svg>
                </SvgShape>
            </div>
            {children}
        </AuthFlowFormContainer>
    </ScreenWrapper>
}
