
import {BaseModel} from "./BaseModel";
import {PlayerQuest, QuestTrack} from "./Quest";
import {City} from "./City"
import {GameLocation} from "./GameLocation";

export enum genderEnum {
    she,
    he,
    they
};

export interface UserBaseModel extends BaseModel {
    userId: string,
};

export interface UserCustomData extends UserBaseModel {
    name: string,
    email: string,
    gender: genderEnum,
};

export interface UserSystemData extends UserBaseModel {
    confirmedEmail: boolean;
    premiumUser: boolean;
    lastUpdate: boolean;
    editor?: boolean;
}

export type LearningLanguage = 'es' | 'en' | 'fr' | 'de' | 'pt';
export type LanguageLevel = 'beginner' | 'intermediate' | 'advanced';

export type SkillNode = { skillId: string, xp: number, parent: string, children: SkillNode[] };

export type UserLanguageGameplayData = {
    language:LearningLanguage;
    languageLevel: LanguageLevel;
    xpTree?: SkillNode[];
}

export type ActiveQuest = { qid: string; cityCode: string };
export type NextLocation = { lid: string; cityCode: string };
export type CityLocations = {cityCode:string, gameLocationIds:string[]};
export type CustomLocationMedia = {lid:string, bg?:string, bgMusic?:string};

export type MapPosition = {
    cityCode: string;
    position: [number, number];
    zoom: number;
}

export interface UserGameplayData extends UserBaseModel {
    translationLanguage?: string;
    Achievements?:string[];
    triggeredEvents?: string[];
    languageSpecific?: {language:LearningLanguage,  userLanguageGameplayData:UserLanguageGameplayData}[];
    questTracks?: QuestTrack[];
    completedQuestsIds?: string[];
    replayQuestsIds?: string[];
    activeQuests?: ActiveQuest[];
    nextLocations?: NextLocation[];
    disableMapHints?: boolean;
    lastOpenLocations?: CityLocations[];
    customLocationsMedia?: CustomLocationMedia[];
    playingCity?: City;
    playingLocation?: GameLocation;
    savedPositions?: MapPosition[];
};
