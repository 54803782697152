import React, {useContext, useEffect, useMemo, useState} from "react";
import {BaseQuestDAL, NodeType, ProdQuestDAL, Quest, QuestDAL, useMongoDB,} from "@pal/common";
import {getAvailableQuests, setAvailableQuestsForCity,} from "../slices/gameSystemSlice";
import {getPlayingCity,} from "../slices/userGamePlaySlice";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
    getActiveQuests,
    getCompletedQuestsIds,
    getQuestTracks, getReplayQuestsIds,
    setActiveQuestInCity,
    setNextLocationId
} from "../slices/userGamePlaySlice";
import {QuestProcessor} from "../components/layout/QuestManager";

type NewQuestsInCity = {newQuestIds: { qid:string, viewed:boolean  }[], cityCode:string};

export interface IQuestService {
    getActiveQuestInCity: () => Quest | undefined;
    getOpenCityQuests: () => Quest[];
    getNewCityQuests: () => { qid:string, viewed:boolean  }[];
    setViewedQuest: (qid:string) => void;
    getCompletedQuests: () => Quest[];
}

const QuestContext = React.createContext<IQuestService | undefined>(
    undefined
);

const rightQuestDAL = (process.env.REACT_APP_PAL_ENV! && process.env.REACT_APP_PAL_ENV === "production") ? ProdQuestDAL : QuestDAL;

export const QuestService = ({ children }) => {
    const { db } = useMongoDB();
    const allAvailableQuests = useAppSelector(getAvailableQuests);
    const completedQuestsIds = useAppSelector(getCompletedQuestsIds);
    const replayQuestsIds = useAppSelector(getReplayQuestsIds);
    const playingCity = useAppSelector(getPlayingCity);
    const activeQuests = useAppSelector(getActiveQuests);
    const [currentActiveQuest, setCurrentActiveQuest] = useState<Quest|undefined>(undefined);
    const questTracks = useAppSelector(getQuestTracks);
    const dispatch = useAppDispatch();
    const questDAL = rightQuestDAL;

    const completedQuests = useMemo(() => {
        return allAvailableQuests?.filter((q)=> completedQuestsIds.includes(q.qid) && !replayQuestsIds.includes(q.qid));
    }, [allAvailableQuests, completedQuestsIds, replayQuestsIds]);

    const replayQuests = useMemo(()=> {
        return allAvailableQuests?.filter((q)=> replayQuestsIds.includes(q.qid));
    },[replayQuestsIds]);
    
    const conditionsMet = (quest)=> {
        if (quest.requirements?.finishedQuests?.length) {
            for (let fq = 0; fq < quest.requirements.finishedQuests.length; fq++) {
                if (!completedQuestsIds.includes(quest.requirements.finishedQuests[fq])) {
                    return false;
                }
            }
        }
        return true;
    };
    const buildOpenQuest = () => {
        if (allAvailableQuests && playingCity) {
            let openQuests:Quest[] = allAvailableQuests?.filter((quest)=>quest.published
                && !completedQuestsIds.includes(quest.qid)
                && quest.city.cityCode === playingCity?.cityCode
                && conditionsMet(quest));
            if (replayQuests.length) {
                openQuests = openQuests.concat(replayQuests);
            }
            //console.log("open quests", openQuests);
            /*if(!questArrayIsEqual(openCityQuests, openQuests)) {
                setOpenCityQuests(openQuests);
            } */
            return openQuests;
        }
        return [];
    }

    const [openCityQuests, setOpenCityQuests] = useState<Quest[]>(buildOpenQuest());
    const [newQuestsInCities, setNewQuestsInCities] = useState<NewQuestsInCity[]>([]); //should save in cloud


    useEffect(() => {
        async function wrapFetch() {
            if (playingCity) {
                const allQuests = await questDAL.fetchPublishedQuests(
                    playingCity._id,
                    "city._id"
                );
                const availableQuests = QuestProcessor(allQuests);
                //console.log("availableQuests", availableQuests);
                dispatch(
                    setAvailableQuestsForCity({
                        cityCode: playingCity.cityCode,
                        availableQuests: availableQuests,
                    })
                );
            }
        }
        if (db && playingCity) {
            wrapFetch().then(()=>{});
        }
    }, [db, playingCity]);

    function questArrayIsEqual(a:Quest[], b:Quest[]) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;
        for (let i = 0; i < a.length; ++i) {
            if (a[i].qid !== b[i].qid) return false;
        }
        return true;
    }

    useEffect(()=> {
        if (allAvailableQuests && playingCity) {
            setOpenCityQuests(buildOpenQuest());
        }
    },[allAvailableQuests, playingCity, replayQuests, completedQuestsIds]);

    useEffect(()=> {
        if (openCityQuests.length) {
            let newQuestIds = newQuestsInCities.find((newQuestsInCities)=> newQuestsInCities.cityCode === playingCity?.cityCode)?.newQuestIds || [];
            openCityQuests.forEach((quest)=>{
                if(!newQuestIds.find((e)=> e.qid === quest.qid)) {
                    newQuestIds.push({qid: quest.qid, viewed:false});
                }
            })
            if (newQuestIds.length && playingCity) {
                const newNewQuestsInCities = newQuestsInCities.filter((nqc)=> nqc.cityCode !== playingCity.cityCode);
                newNewQuestsInCities.push({newQuestIds: newQuestIds, cityCode:playingCity.cityCode})
                setNewQuestsInCities(newNewQuestsInCities)
            }
        }
    },[openCityQuests, playingCity])

    useEffect(()=> {
        let newQuestIds = newQuestsInCities.find((newQuestsInCities)=> newQuestsInCities.cityCode === playingCity?.cityCode)?.newQuestIds.filter((nq)=>!completedQuestsIds.includes(nq.qid))

        if (newQuestIds && playingCity) {
            const newNewQuestsInCities = newQuestsInCities.filter((nqc)=> nqc.cityCode !== playingCity.cityCode);
            newNewQuestsInCities.push({newQuestIds: newQuestIds, cityCode:playingCity.cityCode})
            setNewQuestsInCities(newNewQuestsInCities)
        }

    }, [completedQuestsIds])

    const newQuestsIdsInCity = useMemo(()=> {
        return newQuestsInCities.find((newQuestsInCities)=> newQuestsInCities.cityCode === playingCity?.cityCode)?.newQuestIds || [];
    }, [newQuestsInCities])

    useEffect(()=> {
        if(openCityQuests.length === 1 && playingCity && openCityQuests[0].qid !== currentActiveQuest?.qid) {
            dispatch(setActiveQuestInCity({qid:openCityQuests[0].qid, cityCode: playingCity.cityCode}))
            setCurrentActiveQuest(openCityQuests[0]);
            return
        }
        const aux = activeQuests.find((activeQuest)=>activeQuest.cityCode === playingCity?.cityCode);
        setCurrentActiveQuest(openCityQuests.find((quest)=> quest.qid === aux?.qid));
    },[playingCity, activeQuests, openCityQuests]);


    useEffect(()=> {
        function getNextLocation() {
            const questTrack = questTracks.find((qt)=>qt.qid === currentActiveQuest?.qid);
            if(questTrack) {
                return questTrack.currentLocation;
            } else {
                const nodeId = currentActiveQuest?.edges.find((edge) => edge.source.includes("begin"))?.target
                const node = currentActiveQuest?.nodes.find((node)=> node.id === nodeId && node.type === NodeType.location);
                const lid = node?.data.location.lid;
                return lid;
            }
        }
        dispatch(setNextLocationId(getNextLocation() || ""))
        //activeQuestInCity
    },[playingCity, currentActiveQuest]);

    const getActiveQuestInCity = () => {
        return currentActiveQuest
    }

    const getOpenCityQuests = () => {
        return openCityQuests
    }

    const getNewCityQuests = () => {
        return newQuestsIdsInCity;
    }

    const getCompletedQuests = () => {
        return completedQuests;
    }

    const setViewedQuest = (qid:string) => {
        const currentNewQuestsInCities = newQuestsInCities.find((nqc)=> nqc.cityCode === playingCity?.cityCode);
        if (!currentNewQuestsInCities) return;


        let newQuestIds = currentNewQuestsInCities?.newQuestIds.filter((nq)=>nq.qid !== qid) || [];
        newQuestIds.push({qid:qid, viewed:true});

        const newNewQuestsInCities = newQuestsInCities.filter((nqc)=> nqc.cityCode !== playingCity?.cityCode);
        newNewQuestsInCities.push({newQuestIds: newQuestIds, cityCode:currentNewQuestsInCities.cityCode})

        setNewQuestsInCities(newNewQuestsInCities)
    }

    // @ts-ignore
    return (
        <QuestContext.Provider
            value={{
                getActiveQuestInCity,
                getOpenCityQuests,
                getNewCityQuests,
                setViewedQuest,
                getCompletedQuests
            }}
        >
            {children}
        </QuestContext.Provider>
    );
};

export const useQuestService = () => {
    const context = useContext(QuestContext);
    if (context == null ) {
        throw new Error("useQuestService() called outside of a Provider?");
    }
    return context;
};
