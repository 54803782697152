import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import { createSelector } from 'reselect';

import {
  Quest,
  UserCustomData,
} from "@pal/common";

export interface GameState {
  lastCacheTimestamp: number;
  lastGamePlayDataRefresh?: number;
  availableQuestsStorage: { cityCode: string; availableQuests: Quest[] }[];
  availableQuestsCityLookupTable: string[];

  userCustomData?: UserCustomData;
  unsavedChanges?: boolean;
}

const initialState: GameState = {
  lastCacheTimestamp: -1,

  availableQuestsCityLookupTable: [],
  availableQuestsStorage: [],


};

export const gameSystemSlice = createSlice({
  name: "gameSystem",
  initialState,

  reducers: {
    purgeUserCustomData: (state) => {
      state.userCustomData = undefined;
    },
    purgeQuestSharedData: (state) => {
      state.availableQuestsCityLookupTable = [];
      state.availableQuestsStorage = [];
    },
    setUserCustomData: (state, action: PayloadAction<UserCustomData>) => {
      state.userCustomData = action.payload;
    },
    setLastGameplayDataRefresh: (state, action: PayloadAction<number>) => {
      state.lastGamePlayDataRefresh = action.payload;
    },
    setLastCacheTimestamp: (state, action: PayloadAction<number>) => {
      state.lastCacheTimestamp = action.payload;
    },

    setAvailableQuestsForCity: (
      state,
      action: PayloadAction<{ cityCode: string; availableQuests: Quest[] }>
    ) => {
      if (!state.availableQuestsCityLookupTable) {
        state.availableQuestsCityLookupTable = [];
        state.availableQuestsStorage = [];
      }

      const indexOfCityCode = state.availableQuestsCityLookupTable.indexOf(
        action.payload.cityCode
      );
      if (indexOfCityCode !== -1) {
        state.availableQuestsStorage[indexOfCityCode] = action.payload;
      } else {
        state.availableQuestsStorage.push(action.payload);
        state.availableQuestsCityLookupTable.push(action.payload.cityCode);
      }
    },
  },
});

export const {
  purgeUserCustomData,
  purgeQuestSharedData,
  setUserCustomData,
  setLastCacheTimestamp,
  setLastGameplayDataRefresh,
  setAvailableQuestsForCity,
} = gameSystemSlice.actions;

export const getLastGameplayDataRefresh = (state: RootState) =>
    state.gameSystem.lastGamePlayDataRefresh;
export const getLastCacheTimestamp = (state: RootState) =>
  state.gameSystem.lastCacheTimestamp;
export const getUserCustomData = (state: RootState) =>
  state.gameSystem.userCustomData;

export const getAvailableQuestsStorage = (state: RootState) =>
  state.gameSystem.availableQuestsStorage;

export const getAvailableQuests = createSelector(
    (state: RootState) => state.gameSystem.availableQuestsStorage,
    (availableQuestsStorage) => {
      const quests: Quest[] = [];
      availableQuestsStorage.forEach((s) => quests.push(...s.availableQuests));
      return quests;
    }
);

export const getAvailableQuestsPerCity = (
  state: RootState,
  cityCode: string
) => {
  const indexOfCityCode =
    state.gameSystem.availableQuestsCityLookupTable.indexOf(cityCode);
  if (indexOfCityCode !== -1) {
    return state.gameSystem.availableQuestsStorage[indexOfCityCode]
      .availableQuests;
  } else return [];
};

export default gameSystemSlice.reducer;
