import React, {useState} from "react";
import {useRealmApp} from "@pal/common";
import {AuthWrapper, ErrorContainer, MainActionsWrapper, MyButton, MyLink} from "./AuthComponents";
import {PasswordInput} from "./PasswordInput";
import {useHistory, useLocation} from "react-router-dom";

export function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const ResetPassword = (props:any) => {
    const { resetPassword } = useRealmApp()
    // @ts-ignore
    const query = useQuery();
    const token = query.get('token');
    const tokenId = query.get('tokenId');
    const [sucesfulReset, setSucessfulReset] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState("")
    const [password, setPassword] = useState("")
    const [error, setError] = useState<string | null>(null)
    const history = useHistory();
    const handleReset = async () => {
        if (password != confirmPassword) {
            setError("Passwords don't match")
            return
        }
        // @ts-ignore
        const response = await resetPassword(token, tokenId, password);
        if (response.errorMessage) setError(response.errorMessage);
        else setSucessfulReset(true);
    }


    const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleReset()
        }
    }
    return <AuthWrapper title={"Reset password"} gameApp={"true"}>
        {sucesfulReset ? <>
            <p> successful reset!</p>
            <MainActionsWrapper>
                <MyButton style={{width:"100%"}} variant="contained" onClick={()=>history.push("/")}>Go to login</MyButton>
            </MainActionsWrapper>
            </>:<>
            {error && <ErrorContainer>{error}</ErrorContainer>}
            <PasswordInput id="password" onKeyDown={onInputKeyDown}
                           onChange={(event) => setPassword(event.target.value)} label="Password" variant="filled"
                           value={password}/>
            <PasswordInput id="passwordConfirmation" onKeyDown={onInputKeyDown}
                           onChange={(event) => setConfirmPassword(event.target.value)} label="Confirm password"
                           variant="filled" value={confirmPassword}/>
            <MyLink href={"/forgot-password"}>need a new reset link?</MyLink>
            <MainActionsWrapper>
                <MyButton style={{width: "100%"}} variant="contained" onClick={handleReset}>Reset password</MyButton>
            </MainActionsWrapper>
        </>}
            </AuthWrapper>
}

export default ResetPassword;
