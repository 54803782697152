import { LanguageText } from "@pal/common";
import { Wrapper } from "./NpcDialogueBox";
import MessageDisplay from "./MessageDisplay";

const NarratorBox = (props: {
  showNext: boolean;
  onNext: () => void;
  message?: LanguageText;
}) => {
  return (
    <Wrapper>
      <MessageDisplay
        color={"#84d084"}
        color2={"#c3f3c3"}
        bigVariant={true}
        {...props}
      ></MessageDisplay>
    </Wrapper>
  );
};

export default NarratorBox;
