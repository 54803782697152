import styled from "@emotion/styled";
import {useEffect, useMemo, useState} from "react";
import {
  LanguageLevel,
  LanguageText,
  LanguageTextData,
  LearningLanguage,
  MediaHelper,
  NodeType,
  NpcData,
  QuestTrack,
} from "@pal/common";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {getPlayingCity, getPlayingLocation, setMapPosition,} from "../slices/userGamePlaySlice";
import NpcDialogueBox from "../components/game/NpcDialogueBox";
import PlayerInputArea from "../components/game/PlayerInputArea";
import StageScriptWriter, {playerInputTypes} from "../model/StageScriptWriter";
import {StageNode, StageScript} from "../model/StageScript";
import NarratorBox from "../components/game/NarratorBox";
import {useHistory} from "react-router-dom";
import {fadeInRight, zoomIn} from "react-animations";
import {keyframes} from "@emotion/react";
import {useUserDataService} from "../services/UserDataService";
import {
  addCompletedQuest,
  addTriggeredEvent, finishReplayQuest, getCompletedQuestsIds, getCustomLocationsMedia,
  getQuestTracks, getReplayQuestsIds,
  getTriggeredEvents, setBgAudioSrc, setCustomLocationMedia,
  setNextLocationId,
  setQuestTrack, setVoiceAudioSrc
} from "../slices/userGamePlaySlice";
import {useQuestService} from "../services/QuestService";
import PlayerApartment from "./PlayerApartment";
import PlayerCharacterBox from "../components/game/PlayerCharacterBox";
import QuestCompleted from "../components/game/QuestCompleted";

const screenTransitionIn = keyframes`${fadeInRight}`;
const bigWidthScreenTransitionIn = keyframes`${zoomIn}`;

const WrapperFullBackground = styled.div<{ $height: number; $bgUrl: string }>`
  //height: ${(props) => props.$height}px;
  min-height: 100%;
  height: fit-content;
  width: 100%;
  background: rgb(66, 36, 103);
  background: linear-gradient(
      0deg,
      rgb(53, 29, 81) 0%,
      rgba(110, 178, 255, 0.4) 10%,
      rgba(110, 178, 255, 0.2) 90%,
      rgb(46, 24, 71) 100%
    ),
    url(${(props) => props.$bgUrl}) no-repeat center center;
  background-position-y: 0;
  background-size: cover;
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow: auto;
  overflow-x: hidden;
  animation: 0.8s ${screenTransitionIn} ease-in-out;
  @media (min-width: 700px) {
    animation: 0.8s ${bigWidthScreenTransitionIn} ease-in-out;
  }
`;
export function getLanguageText(
  languageLevel: LanguageLevel,
  node: LanguageTextData | undefined
) {
  switch (languageLevel) {
    case "beginner":
      return (
        node?.beginnerLine ||
        node?.intermediateLine ||
        node?.advancedLine ||
        undefined
      );
    case "intermediate":
      return (
        node?.intermediateLine ||
        node?.beginnerLine ||
        node?.advancedLine ||
        undefined
      );
    case "advanced":
      return (
        node?.advancedLine ||
        node?.intermediateLine ||
        node?.beginnerLine ||
        undefined
      );
    default:
      return (
        node?.intermediateLine ||
        node?.beginnerLine ||
        node?.advancedLine ||
        undefined
      );
  }
}

export const getGenderMessage = (languageText, gender) => {
  return gender === 0 ? languageText?.ftext || languageText?.text || "" : languageText?.text || ""
}

export const ConditionalTrueEdge = "trueOut";
export const ConditionalFalseEdge = "falseOut";

export function convertLanguageNameToLanguageCode (name) {
  if (!name) {
    return "es";
  }
  switch (name.toLowerCase()) {
    case "spanish": return "es";
    case "portuguese": return "pt";
    case "english": return "en";
    case "deutsch": return "de";
    case "german": return "de";
    case "french": return "fr";
  }
}

const GameLocationStage = () => {
  const dispatch = useAppDispatch();
  const playingCity = useAppSelector(getPlayingCity);
  const playingLocation = useAppSelector(getPlayingLocation);

  const isApartment = useMemo(()=>playingLocation?.lid.toLowerCase().includes("apartment")||false,[playingLocation])

  const playingLanguage: LearningLanguage = convertLanguageNameToLanguageCode(playingLocation?.city.language) || 'es';
  const userDataService = useUserDataService();
  const userLanguageGPData =
    userDataService.getLanguageGameplayData(playingLanguage);
  const languageLevel:LanguageLevel = userLanguageGPData?.languageLevel || 'intermediate';
  const questService = useQuestService();
  const openQuests = questService.getOpenCityQuests();
  const triggeredEvents = useAppSelector(getTriggeredEvents);
  const questTracks = useAppSelector(getQuestTracks);
  const [stageScript, setStageScript] = useState<StageScript>({
    preInputSequence: [],
    playerInput: [],
  });
  const [currentPreInputStageNode, setCurrentPreInputStageNode] =
    useState<StageNode>();
  const [stepsInStageScript, setStepsInStageScript] = useState<number>(0);
  const [npcData, setNpcData] = useState<NpcData | null>();
  const [languageTextData, setLanguageTextData] = useState<LanguageTextData>();
  const messageDefault =
    playingLocation?.defaultDialogue && playingLocation?.defaultDialogue["1"]
      ? playingLocation!.defaultDialogue["1"]
      : undefined;
  const [currentStoryBox, setCurrentStoryBox] = useState<"narrator" | "npc" | "playerCharacter">(
    "npc"
  );
  const [showNext, setShowNext] = useState<boolean>(false);
  const [nodeDisplaysStoryBox, setNodeDisplaysStoryBox] = useState<boolean>(true);
  const [finishedProcessing, setFinishedProcessing] = useState<boolean>(false);
  const [leavingStage, setLeaving] = useState<boolean>(false);
  const completedQuestsIds = useAppSelector(getCompletedQuestsIds);
  const replayQuestsIds = useAppSelector(getReplayQuestsIds);
  const customLocationsMedia = useAppSelector(getCustomLocationsMedia);
  const customLocationMedia = useMemo(()=>{
    return customLocationsMedia.find(clm => clm.lid === playingLocation?.lid)
      }
  ,[customLocationsMedia])

  const history = useHistory();
  const pushToCity = () => (playingCity) ? history.push('/city/'+playingCity.cityCode) : history.push("/");//history.goBack();
  const activeQID = questService.getActiveQuestInCity()?.qid || "";
  const [isQuestCompleted, setQuestCompleted] = useState<boolean>(false);

  useEffect(()=>{
    if (customLocationMedia?.bgMusic && customLocationMedia?.bgMusic !== "") {
      dispatch(setBgAudioSrc(customLocationMedia?.bgMusic));
    } else if (playingLocation?.defaultBgMusic) {
      dispatch(setBgAudioSrc(MediaHelper.getUrlFromAsset(playingLocation.defaultBgMusic)));
    }

  }, [playingLocation, customLocationMedia])
  //reset setup
  useEffect(() => {
    if (playingLocation && !leavingStage) {
      const newStageScript = StageScriptWriter(
        playingLocation.lid,
        openQuests,
        questTracks, activeQID
      );
      setStageScript(newStageScript);
      //console.log("newStageScript", newStageScript);
      if (!newStageScript.preInputSequence.length && !newStageScript.playerInput.length && playingLocation?.isPlayerHome) {
        setCurrentStoryBox("narrator")
        //addDefaultInteraction()
      }
      setStepsInStageScript(0);

      // TODO compare with filtered array with only navigable nodes
      if (newStageScript.preInputSequence.length > 1) {
        setShowNext(true);
      }
    }
  }, [questTracks, playingLocation]);

  //process new StageScript
  useEffect(() => {
    //iterate over preInputSequence by shift() its members asynchronously
    // most nodes require player input to move forward, except updating bgm or bg
    //Display playInput when preInputSequence is empty
    setCurrentPreInputStageNode(stageScript.preInputSequence.shift());
    if (!stageScript.preInputSequence.length && stageScript.playerInput.length)
      setShowNext(false);
  }, [stageScript, stepsInStageScript]);

  const questFromCurrentstageNode = useMemo(()=> {
        return openQuests.find((q) => q.qid === stageScript.mainQuest);
  }, [openQuests, stageScript]);

  const currentTrack = useMemo(()=> {
    return questTracks.find(
        (qt) => qt.qid === questFromCurrentstageNode?.qid
    );
  }, [questFromCurrentstageNode, questTracks]);
  //proccess currentNode
  useEffect(() => {
    //console.log('currentPreInputStageNode', currentPreInputStageNode);

    if (currentPreInputStageNode) {

      switch (currentPreInputStageNode.questNode.type) {
        case NodeType.location:
          setNodeDisplaysStoryBox(false);
          const nodeLocation = currentPreInputStageNode.questNode.data.location.lid;
          if (nodeLocation !== playingLocation!.lid) {
            // store progress in quest tracker; (? - to confirm)
            // Stop calling next
          } else {
            if (currentPreInputStageNode.questNode.data.alternativeBackground) {
              const locationBackground = MediaHelper.getUrlFromAsset(currentPreInputStageNode.questNode.data.alternativeBackground);
              if (locationBackground !== customLocationMedia?.bg) {
                const defaultBackgroundUrl = MediaHelper.getUrlFromAsset(playingLocation?.defaultBackground!);
                const newLocationBackground = locationBackground !== defaultBackgroundUrl ? locationBackground : "";
                const newCustomLocationMedia = {lid: currentPreInputStageNode.questNode.data.location.lid, bg:newLocationBackground};
                const mergedCustomMedia = { ...customLocationMedia, ...newCustomLocationMedia };
                dispatch(setCustomLocationMedia(mergedCustomMedia));
              }
            }

            if (currentPreInputStageNode.questNode.data.alternativeBgMusic) {
              const locationBgMusic = MediaHelper.getUrlFromAsset(currentPreInputStageNode.questNode.data.alternativeBgMusic);

              if (locationBgMusic !== customLocationMedia?.bgMusic) {
                const defaultBgMusicUrl = MediaHelper.getUrlFromAsset(playingLocation?.defaultBgMusic!);
                const newLocationBgMusic = locationBgMusic !== defaultBgMusicUrl ? locationBgMusic : "";
                const newCustomLocationMedia = {lid: currentPreInputStageNode.questNode.data.location.lid, bgMusic:newLocationBgMusic};
                const mergedCustomMedia = { ...customLocationMedia, ...newCustomLocationMedia };
                dispatch(setCustomLocationMedia(mergedCustomMedia));
              }
            }
            onNext();
          }
          break;
        case NodeType.triggerEvent:
          //show congratulations after leaving the city.
          setNodeDisplaysStoryBox(false);
          dispatch(addTriggeredEvent(currentPreInputStageNode.questNode.data.gameEvent.geid))
          onNext();
          break;
        case NodeType.updateCoordinates:
          setNodeDisplaysStoryBox(false);
          const nodeData = currentPreInputStageNode.questNode.data;
          dispatch(
              setMapPosition({
                cityCode: playingCity!.cityCode,
                position: [nodeData.lat, nodeData.lng],
                zoom: nodeData.zoom || 15,
              })
          );
          updateStepInQuestTrack(currentPreInputStageNode);
          if (nodeData.leaveToCity) {
            leaveToCity();
          }
          else {
            onNext();
          }
          break;
        case NodeType.end:

          setNodeDisplaysStoryBox(false);
          if (questFromCurrentstageNode) {
            if (replayQuestsIds.includes(questFromCurrentstageNode.qid)) {
              dispatch(finishReplayQuest(questFromCurrentstageNode.qid))
            } else {
              dispatch(addCompletedQuest(questFromCurrentstageNode.qid))
            }
          }

          //show congratulations after leaving the city
          setQuestCompleted(true);
          //leaveToCity();
          break;
        case NodeType.conditional:
          setNodeDisplaysStoryBox(false);
          const conditionsAreMet = (data) => {
            if (data.triggeredEvents) {
              for(let i=0; i < data.triggeredEvents.length; i++) {
                const triggeredEvent = data.triggeredEvents[i];
                if(!triggeredEvents?.includes(triggeredEvent))
                  return false
              }
            }
            if (data.questNodeIds && data.questNodeIds.trim()!=='') {
              const questNodeIds = data.questNodeIds.trim().split(',').map((entry)=>entry.trim());

              for(let i=0; i < questNodeIds.length; i++) {
                const qid = questNodeIds[i];
                if(!currentTrack?.visitedNodes?.includes(qid)) {
                  return false
                }
              }
            }
            if (data.finishedQuests?.length) {
              for(let i=0; i < data.finishedQuests.length; i++) {
                const qid = data.finishedQuests[i];
                if(!completedQuestsIds?.includes(qid))
                  return false
              }
            }
            return true;
          }

          const data = currentPreInputStageNode.questNode.data;

          const conditionsMet = conditionsAreMet(data)

          const nextStartNodeId = (conditionsMet) ? data.trueOut?.target : data.falseOut?.target;

          const nextQuestNode = questFromCurrentstageNode?.nodes.find((node)=>node.id===nextStartNodeId)

          if (nextQuestNode) {
            if (playerInputTypes.includes(nextQuestNode.type?.toString())) {
              const sourceHandle = (conditionsMet) ? ConditionalTrueEdge : ConditionalFalseEdge;
              // @ts-ignore
              const inputsToKeepInGivenCondition = questFromCurrentstageNode?.edges.filter((edge)=>(edge.source===currentPreInputStageNode.questNode.id && edge.sourceHandle===sourceHandle)).map((edge)=>edge.target);
              stageScript.playerInput = stageScript.playerInput.filter((element) => (inputsToKeepInGivenCondition?.includes(element.questNode.id)));
            } else {
              updateStepInQuestTrack({ qid: questFromCurrentstageNode!.qid, questNode: nextQuestNode }, currentPreInputStageNode.questNode.id);
            }
          }
          onNext();
          break;
        case NodeType.goBackTo:
          setNodeDisplaysStoryBox(false);
          const goBackId = currentPreInputStageNode.questNode.data.text;

          const node = questFromCurrentstageNode?.nodes?.find((n) => n.id == goBackId);
          if (node) {
            if (!currentPreInputStageNode.questNode.data.dontLeaveNode) {
              leaveToCity();
            } else {
              //updateQuestScript
            }
            updateStepInQuestTrack({ qid: questFromCurrentstageNode!.qid, questNode: node });
          }
          break;
        case NodeType.npc:
          setNodeDisplaysStoryBox(true);
          const npcData = currentPreInputStageNode.questNode.data;
          setNpcData(npcData);
          setCurrentStoryBox("npc");
          if (
            !stageScript.preInputSequence.length &&
            !stageScript.playerInput.length
          ) {
            updateStepInQuestTrack(currentPreInputStageNode);
          }
          break;
        case NodeType.narrator:
          setNodeDisplaysStoryBox(true);
          const languageText = currentPreInputStageNode.questNode.data;
          setLanguageTextData(languageText);
          setCurrentStoryBox("narrator");
          break;
        case NodeType.playerCharacter:
          setNodeDisplaysStoryBox(true);
          const playerText = currentPreInputStageNode.questNode.data;
          setLanguageTextData(playerText);
          setCurrentStoryBox("playerCharacter");
          break;
      }
    }
    setFinishedProcessing(true);
  }, [currentPreInputStageNode]);

  function updateStepInQuestTrack(newCurrentNode: StageNode, activeConditionId?:string) {
    console.log("newCurrentNode updateStepInQuestTrack", newCurrentNode);
    if(!newCurrentNode || !newCurrentNode?.questNode)
      return;
    setFinishedProcessing(false);
    const initialTrack = questTracks.find(
      (qt) => qt.qid === newCurrentNode.qid
    );
    let questTrack: QuestTrack = (initialTrack)
      ? { ...initialTrack }
      : {
          qid: newCurrentNode.qid,
          stepsDone: 0,
          currentLocation: playingLocation?.lid,
          activeConditionId: activeConditionId
        };

    const currentQuest = openQuests.find((quest)=>quest.qid === newCurrentNode.qid)
    const nextLocationNodeId = currentQuest?.edges.find((edge) => edge.source === newCurrentNode.questNode.id && edge.target.includes("location"))?.target
    const nextLocationNode = currentQuest?.nodes.find((node)=> node.id === nextLocationNodeId && node.type === NodeType.location);
    const nextLocation = nextLocationNode?.data.location.lid;
    //console.log("nextLocation", nextLocation)
    questTrack.stepsDone++;
    questTrack.currentNodeId = newCurrentNode.questNode.id;
    if(nextLocation && nextLocation !== playingLocation?.lid && currentQuest?.qid === activeQID) {
      dispatch(setNextLocationId(nextLocation));
    }
    questTrack.currentLocation = nextLocation || playingLocation?.lid;

    if (!questTrack.visitedNodes) {
      questTrack.visitedNodes = [newCurrentNode.questNode.id];
    } else if (questTrack.visitedNodes && !questTrack.visitedNodes.includes(newCurrentNode.questNode.id)) {
      questTrack.visitedNodes = [...questTrack.visitedNodes, newCurrentNode.questNode.id]
    }
    questTrack.conditionToReprocess = "";
    questTrack.activeConditionId = activeConditionId;
    if (newCurrentNode.questNode.data?.leaveNode) leaveToCity(); //TODO check if its ok to leave before setting state

    dispatch(
      setQuestTrack({ qid: newCurrentNode.qid, questTrack: questTrack })
    );
  }


  const url = customLocationMedia?.bg && customLocationMedia?.bg !== "" ? customLocationMedia?.bg : playingLocation && MediaHelper.getUrlFromAsset(playingLocation?.defaultBackground!)
  const bgUrl = url ? url : "";

  function updateConditionRollback() {
    if (!currentTrack || !questFromCurrentstageNode) return
    let newCurrentTrack = {...currentTrack};
    if (newCurrentTrack.activeConditionId ) {
      newCurrentTrack.conditionToReprocess = newCurrentTrack.activeConditionId;
      newCurrentTrack.activeConditionId = undefined;

      dispatch(
          setQuestTrack({ qid: questFromCurrentstageNode.qid, questTrack: newCurrentTrack })
      );
    }
  }
  function leaveToCity() {
    setLeaving(true);
    dispatch(setBgAudioSrc(""));
    dispatch(setVoiceAudioSrc(""));
    setFinishedProcessing(false);
    updateConditionRollback();
    pushToCity();
    //saveProgress

  }
  function onNext() {
    setFinishedProcessing(false);
    setStepsInStageScript(stepsInStageScript + 1);
    setNodeDisplaysStoryBox(true);
    //setNpcData(null);
  }

  function buildStoryBox() {
    let message;
    switch (currentStoryBox) {
      case "narrator":
        message =
          getLanguageText(languageLevel, languageTextData) || messageDefault;
        return (
          <NarratorBox showNext={showNext} onNext={onNext} message={message} />
        );
      case "playerCharacter":
        message =
            getLanguageText(languageLevel, languageTextData) || messageDefault;
        return (
            <PlayerCharacterBox showNext={showNext} onNext={onNext} message={message} />
        );
      case "npc":
        const character = npcData?.character || playingLocation!.character;
        message =
          (npcData && getLanguageText(languageLevel, npcData)) ||
          messageDefault;
        return (
          <NpcDialogueBox
            showNext={showNext}
            onNext={onNext}
            character={character}
            imgRef={npcData?.imgRef}
            message={message}
            languageLevel={languageLevel}
          />
        );
    }
  }

  const DEFAULT_LEAVE_OPTION = "DEFAULT";
  function onInputClick(node: StageNode) {
    if (node.qid === DEFAULT_LEAVE_OPTION) return leaveToCity();
    //setNpcData(null); dont clear the npc, keeping the same message in case a following player input is required.q
    updateStepInQuestTrack(node);
  }

  function buildInputArea() {
    const inputNodes = stageScript && [...stageScript.playerInput];

    if (!inputNodes.length) {
      const lastPlayerOptions = playingLocation?.lastPlayerOption || {
        1: { text: "bye" },
        2: { text: "ciao" },
        3: { text: "adios" },
      };

      const lastPlayerOptionsArray: LanguageText[] = [];
      for (let i = 1; i <= 3; i++) {
        if (lastPlayerOptions[i])
          lastPlayerOptionsArray.push(lastPlayerOptions[i]);
      }
      const lastPlayerAsInputNodes: StageNode[] = lastPlayerOptionsArray.map(
        (languageText, index) => {
          return {
            qid: DEFAULT_LEAVE_OPTION,
            questNode: {
              id: DEFAULT_LEAVE_OPTION + index,
              type: NodeType.player,
              data: {
                beginnerLine: languageText,
              },
            },
          };
        }
      );
      inputNodes.push(...lastPlayerAsInputNodes);
    }
    return (
      <PlayerInputArea
        languageLevel={languageLevel}
        onClickToLeave={leaveToCity}
        onInputClick={onInputClick}
        inputNodes={inputNodes}
      />
    );
  }

  const inputArea =
      finishedProcessing && !showNext ? (
          buildInputArea()
      ) : (
          <div style={{ flex: "1 1 auto" }}></div>
      );
  const storyBox = playingLocation ? (
    finishedProcessing && buildStoryBox()
  ) : (
    <div
      style={{
        display: "flex",
        verticalAlign: "center",
        justifyContent: "center",
      }}
    >
      no playing location set
    </div>
  );


  return (
    <WrapperFullBackground $height={100} $bgUrl={bgUrl}>
      {isQuestCompleted ? <QuestCompleted quest={questService.getActiveQuestInCity()} nextButtonCallback={()=>{leaveToCity()}}/>:
      /*isApartment && stageScript.playerInput.length === 0 ? <PlayerApartment /> :*/
          <>
      {nodeDisplaysStoryBox && storyBox}
      {inputArea}
          </>}
    </WrapperFullBackground>
  );
};

export default GameLocationStage;
