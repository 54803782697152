import * as RealmWeb from "realm-web"
import {LocalStorage} from "realm-web"

import React, {useContext, useState} from "react"


const RealmAppContext = React.createContext({})

// @ts-ignore
export const RealmApp = ({ children }) => {
    const REALM_APP_ID = process.env.REACT_APP_REALM_APP_ID;
    const storedApp = RealmWeb.App.getApp(REALM_APP_ID);
    // @ts-ignore
    const app = (storedApp) ? storedApp : new RealmWeb.App({ id: REALM_APP_ID, storage:LocalStorage });
    const [user, setUser] = useState(app.currentUser);

    const logIn = async (email, password) => {
        const credentials = RealmWeb.Credentials.emailPassword(email, password)
        try {
            await app.logIn(credentials);
            setUser(app.currentUser)
            return app.currentUser;
        } catch (e) {
            console.log(e);
            setUser(null)
            return null
        }
    }

    const signUp = async (email, password) => {

        try {
            await app.emailPasswordAuth.registerUser(email, password)
            return {status: "success"}
        } catch (e) {
            console.log("catch email");
            console.log(e);
            let errorMessage = (e.message.includes("status 409"))? "This email is already registered" : e.message;
            return {status: "error", errorMessage:errorMessage}
        }
    }

    const resendConfirmationEmail = async (mailToken, token) => {

        try {
            const confirmed = await  user.functions.ResendConfirmationEmail(mailToken, token);
            return confirmed;
        } catch (e) {
            console.log(e);
            return {status:"failed", errorMessage:e.message}
        }
    }

    const confirmEmail = async (mailToken, token) => {

        try {
            const confirmed = await  user.functions.ConfirmUserEmail(mailToken, token);
            return confirmed;
        } catch (e) {
            console.log(e);
            return {status:"failed", errorMessage:e.message}
        }
    }

    const requestTextTranslation = async (text, targetLanguage) => {
        try {
            const translatedText = await user.functions.TranslateText(text, targetLanguage);
            return translatedText;
        } catch (e) {
            console.log(e);
            return "Translation error";
        }
    }

    const resendConfirmation = async (email) => {

        try {
            const response = await app.emailPasswordAuth.resendConfirmationEmail(email);
            return response;
        } catch (e) {
            console.log(e);
            return {status:"failed", errorMessage:e.message}
        }
    }

    const sendResetPasswordEmail = async (email) => {

        try {

            await app.emailPasswordAuth.callResetPasswordFunction(email, "102030AABB!", null)
            return true;
        } catch (e) {
            console.log(e);
            return null
        }
    }

    const resetPassword = async (token, tokenId, password) => {
        const args = [];
        const response = await app.emailPasswordAuth.resetPassword(token, tokenId, password)
            .then( result =>{
                return res.json();
            }).catch( error => {
                const errorMessage = (error.error === "invalid token data") ? "Link expired" : error.error;
                return {status: "error", errorMessage: errorMessage}
            });
        return response
    }



    const logOut = () => {
        if (user !== null) {
            app?.currentUser?.logOut()
            setUser(null)
        }
    }

    return (
        <RealmAppContext.Provider
            // @ts-ignore
            value={{
                logIn,
                logOut,
                signUp,
                confirmEmail,
                resendConfirmation,
                sendResetPasswordEmail,
                resetPassword,
                requestTextTranslation,
                user,
            }}
        >
            {children}
        </RealmAppContext.Provider>
    )
}

export const useRealmApp = () => {
    const realmContext = useContext(RealmAppContext)
    if (realmContext == null) {
        throw new Error("useRealmApp() called outside of a RealmApp?")
    }
    return realmContext
}

//export default RealmApp
