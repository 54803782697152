import styled from "@emotion/styled";
import {Logo} from "./Logo";
import React, {useState} from "react";
import {ButtonIcon} from "./ButtonIcon";
import logo from "../../assets/pallogo.png";
import {useLocation} from "react-router-dom";
import {useAppSelector} from "../../app/hooks";
import {getPlayingCity,} from "../../slices/userGamePlaySlice";
import ProfileMenuWidget from "./ProfileMenuWidget";
import {useAudioService} from "../../services/AudioService";
import MusicNoteIcon from '@mui/icons-material/MusicNote';
import MusicOffIcon from '@mui/icons-material/MusicOff';

const HeaderContainer = styled.header`
  background: transparent;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  z-index: 20;
  pointer-events: none;
`;
export const Column = styled.div`
  display: flex;
  flex: 1 1 auto;
  padding: 5%;
  align-items: center;
`;
const Header = () => {
  const playingCity = useAppSelector(getPlayingCity);
  const location = useLocation();
  const audioService = useAudioService();
  const [enabledAudio, setEnabledAudio] = useState<boolean>(audioService.isPlayingAudio())

  const atCity =
    playingCity && location.pathname.includes("/city/")
      ? playingCity!.name
      : undefined;

  const toggleAutoPlay = () => {
    if (!enabledAudio) {
      audioService.enableAutoPlay();
    } else {
      audioService.disableAutoPlay();
    }

    setEnabledAudio(!enabledAudio)
  }
  return (
    <HeaderContainer>
      <Column>
        <Logo $overMap={atCity !== undefined}>
          <img src={logo} />
          <span>{atCity && `${playingCity?.name}`}</span>
        </Logo>
      </Column>
      <Column style={{ justifyContent: "flex-end" }}>
        <ButtonIcon variant="contained" size="small" style={{pointerEvents: "all"}} onClick={()=>toggleAutoPlay()}>
          {enabledAudio ? <MusicNoteIcon /> : <MusicOffIcon />}
        </ButtonIcon>
        <ProfileMenuWidget />
      </Column>
    </HeaderContainer>
  );
};

export default Header;
