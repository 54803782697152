import {Avatar, Box, Button, LinearProgress, Typography} from "@mui/material";
import styled from "@emotion/styled";
import {getUrlFromAsset, Quest} from "@pal/common";
import {CheckCircle} from "@mui/icons-material";
import {keyframes} from "@emotion/react";
import {bounceIn, merge, zoomIn, } from "react-animations";
import React from "react";
import {useTranslation} from "react-i18next";

const mergedAnimation = merge(zoomIn, bounceIn);
const titleAnimation = keyframes`${mergedAnimation}`;
const containerAnimation = keyframes`${zoomIn}`;

const Container = styled.div`
  height: 45vh;
  margin: auto;
  margin-top: 25vh;
  max-width: 600px;
  width: 90%;
  background: #fdcd84;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  clip-path: polygon(0px -1px, 99.2% 1.6%, 95.50% 97%, 4% 99%);
  background: linear-gradient(180deg, #fdcd84f1 95%, rgb(105, 70, 185) 100%);
  animation: ${containerAnimation} 0.5s ease-in-out;
`;

const AnimatedTitle = styled(Typography)`
  animation: ${titleAnimation} 2s ease-in-out;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

const ProgressBarContainer = styled.div`
  margin: 20px 0;
  width:80%;
`;

const QuestInfoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 20px;
`;

const QuestCompleted = (props:{quest?:Quest; nextButtonCallback:()=>void}) => {
    const { t } = useTranslation();

    const [progressValues, setProgressValues] = React.useState({
        questSuccess: 0,
        skills: {}
    });

    const targetValues = {
        questSuccess: 70,
        skills: [
            { name: 'Skill 1', target: 80 },
            { name: 'Skill 2', target: 50 },
            { name: 'Skill 3', target: 95 },
        ]
    };

    React.useEffect(() => {
        const interval = setInterval(() => {
            let updatedSkills = { ...progressValues.skills };
            let allSkillsCompleted = true;

            for (const skill of targetValues.skills) {
                updatedSkills[skill.name] = Math.min((updatedSkills[skill.name] || 0) + 1, skill.target);

                // Check if any skill hasn't completed yet
                if (updatedSkills[skill.name] < skill.target) {
                    allSkillsCompleted = false;
                }
            }

            const questIsComplete = progressValues.questSuccess >= targetValues.questSuccess;

            // If all skills and the quest are complete, clear the interval
            if (allSkillsCompleted && questIsComplete) {
                clearInterval(interval);
            } else {
                setProgressValues(prevValues => ({
                    questSuccess: Math.min(prevValues.questSuccess + 1, targetValues.questSuccess),
                    skills: updatedSkills
                }));
            }
        }, 20);

        return () => clearInterval(interval);  // Cleanup to prevent potential memory leaks
    }, [targetValues, progressValues]);

    const questTitle = props.quest?.name;
    const avatarURL = props.quest?.giver?.portrait && getUrlFromAsset(props.quest?.giver?.portrait);

    return (
        <Container>
                <QuestInfoContainer>
                    <Typography variant="h6">{questTitle}</Typography>
                    <Avatar src={avatarURL} alt="Quest Giver" />
                </QuestInfoContainer>

                <CheckCircle fontSize="large" color="primary" />
                <AnimatedTitle variant="h4">{t("questCompleted.completed")}</AnimatedTitle>
            {/*
                <ProgressBarContainer>
                    <Typography>Quest Success</Typography>
                    <LinearProgress variant="determinate" value={progressValues.questSuccess} />
                </ProgressBarContainer>

                <div>
                    <Typography>Skills Progress</Typography>
                    {targetValues.skills.map(skill => (
                        <ProgressBarContainer key={skill.name}>
                            <Typography>{skill.name}</Typography>
                            <LinearProgress variant="determinate" value={progressValues.skills[skill.name] || 0} color="secondary" />
                        </ProgressBarContainer>
                    ))}
                </div>*/}
            <Button onClick={props.nextButtonCallback} variant={"contained"} sx={{mt:5}}>{t("questCompleted.next")}</Button>
        </Container>);

}

export default QuestCompleted;
