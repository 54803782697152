import MultipleChoicePuzzle from "./MultipleChoicePuzzle";
import { LanguageLevel, LanguageTextData, PuzzleTypes } from "@pal/common";
import { PuzzleData } from "@pal/common";
import styled from "@emotion/styled";
import { StageNode } from "../../../model/StageScript";

export const PuzzleTitleBadgeWrapper = styled.div`
  position: absolute;
  top: -20px;
  left: 50% - 125px;
  font-weight: bold;
  font-size: 1.2em;
  padding: 10px 20px;
  width: 250px;
  height: 60px;
  background: rgb(102, 0, 225);
  background: linear-gradient(0deg, rgb(102, 0, 225) 0%, rgb(171, 128, 223) 100%);
  color: #fff;
  clip-path: polygon(0% 0%, 97% 4.81%, 90% 97%, 14px 100%);
`;

export const PuzzleTitleBadge = (props) =>{
    return <PuzzleTitleBadgeWrapper>{props.children}</PuzzleTitleBadgeWrapper>
}

export const PuzzleWrapper = styled.div`
  flex: 1 1 auto;
  text-align: center;
  backdrop-filter: blur(10px);
  background: rgba(70, 46, 113, 0.68);
  //border-radius: 20px;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 30px;
  //clip-path: polygon(0.13% 0%, 97.26% 4.81%, 88.36% 97.6%, 14px 102.4%);
`;

export function getLanguagePuzzleData(languageLevel: LanguageLevel, puzzleData: PuzzleData | undefined) {
  //TODO get based on user preference
  switch (languageLevel) {
    case "beginner":
      return (
          puzzleData?.I ||
          puzzleData?.II ||
          puzzleData?.III ||
          undefined
      );
    case "intermediate":
      return (
          puzzleData?.II ||
          puzzleData?.I ||
          puzzleData?.III ||
          undefined
      );
    case "advanced":
      return (
          puzzleData?.III ||
          puzzleData?.II ||
          puzzleData?.I ||
          undefined
      );
    default:
      return (
          puzzleData?.II ||
          puzzleData?.I ||
          puzzleData?.III ||
          undefined
      );
  }
}

const InputPuzzle = (props: {
  languageLevel: LanguageLevel;
  stageNode: StageNode;
  style: any;
  onRightAnswer: () => void;
  onWrongAnswer: () => void;
}) => {
  const { stageNode } = props;
  switch (stageNode.questNode.data.puzzleType) {
    case PuzzleTypes.multipleChoice:
      return <MultipleChoicePuzzle {...props} />;
    default:
      return (
        <div>
          <p>{stageNode.toString()}</p>
        </div>
      );
  }
};

export default InputPuzzle;
