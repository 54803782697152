import styled from "@emotion/styled";
import UndoIcon from "@mui/icons-material/Undo";
import { NavigationButton } from "../layout/NavigationBar";
import { StageNode } from "../../model/StageScript";
import {
  DefaultOptions,
  getUrlFromAsset,
  LanguageLevel,
  LanguageText,
  NodeType,
  PlayerData,
  shuffleArray,
} from "@pal/common";
import {getGenderMessage, getLanguageText} from "../../screens/GameLocationStage";
import MultipleChoicePuzzle from "./puzzleRenderers/MultipleChoicePuzzle";
import InputPuzzle from "./puzzleRenderers/InputPuzzle";
import { useEffect, useState } from "react";
import { flipInY, pulse } from "react-animations";
import { keyframes } from "@emotion/react";
import {useUserDataService} from "../../services/UserDataService";
import {useTranslation} from "react-i18next";
import {Box} from "@mui/material";
import {useAppSelector} from "../../app/hooks";
import {getPlayingCity} from "../../slices/userGamePlaySlice";

const playerInputAnimation = keyframes`${flipInY}`;
const playerInputAnimationDesktop = keyframes`${pulse}`;

const Wrapper = styled.div`
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-flow: column;
`;

const OptionContainer = styled.button<{
  imgContainer?: boolean;
  disabled?: boolean;
}>`
  position: relative;
  background: #90b2ee33;
  width: 95%;

  max-width: 700px;
  min-width: ${(props) => (props.imgContainer ? "100px" : "300px")};
  min-height: ${(props) => (props.imgContainer ? "100px" : "60px")};
  max-height: 200px;

  height: fit-content;
  padding: ${(props) => (props.imgContainer ? "20px" : "5px 50px")};
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px auto;
  text-align: center;

  font-size: 1.4em;
  color: white;
  font-family: "Saira Condensed";
  font-weight: 500;
  cursor: pointer;
  background: #b185ff;
  //background: linear-gradient( 180deg, #ee9f4b 0%, rgba(174, 74, 0, 0.75) 100% );

  //border: 1px solid #c2aaff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.35);
  //border-radius: 10px;
  transition: all 0.2s ease-in-out;

    //clip-path: ${(props) => (props.imgContainer ? "" : "polygon(10% 0%, 100% 0%, 90% 100%, 0px 100%)")};
  clip-path: polygon(6.86% 9%, 96.29% 3%, 89.43% 91%, 10% 86.00%);

  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  transition: all 0.2s ease-in-out;
  animation: 0.8s ${playerInputAnimation};

  img {
    height: 100%;
    max-height: 150px;
    object-fit: contain;
    z-index: 1;
    width: 100%;
  }

  ${(props) =>
      props.disabled
          ? "background: linear-gradient(180deg, #969595 0%, rgba(129, 129, 129, 0.75) 100%);"
          : ""}
  &:focus:enabled {
    outline: none;
  }

  &:hover:enabled {
    transform: scale(1.01);
    background: rgb(184, 120, 203);
    border: 1px solid #e0c3f5;
  }

  &:before {
    content: '';
    background: #462886;
    background: linear-gradient(180deg, #462886 0%, rgba(111, 75, 208, 0.75) 100%);
    display: block;
    position: absolute;
    top: 1px;
    left: 1px;
    right: 1px;
    bottom: 1px;
    clip-path: polygon(6.86% 9%, 96.29% 3%, 89.43% 91%, 10% 86.00%);
  }

  div {
    z-index: 1;
  }


  @media (min-width: 700px) {
    margin: 10px;
    width: ${(props) => (props.imgContainer ? "200px" : "350px")};
    height: ${(props) => (props.imgContainer ? "200px" : "100px")};
    animation: 0.8s ${playerInputAnimationDesktop};
  }
`;

const PlayerOptions = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  justify-content: center;
  margin-top: 5px;
  padding: 0px 10px;
  @media (min-width: 700px) {
    flex-flow: row;
    flex-wrap: wrap;
  }
`;
interface PlayerOptionProps {
  children?: React.ReactNode;
  style?: any;
  imgSrc?: string;
  disabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

const WrongBadge = styled.div`
  height: 24px;
  width: 24px;
  position: relative;
  background-color: #ff8c88;
  border-radius: 5px;
  margin-left: 5px;

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: "X";
    font-size: 12px;
    font-weight: bold;
    color: #ffe5e5;
    line-height: 24px;
    text-align: center;
  }
`;

const PlayerImgOption = (props: PlayerOptionProps) => {
  const { imgSrc, onClick, style, disabled } = props;
  return (
    <OptionContainer
      disabled={disabled}
      style={style}
      onClick={onClick}
      imgContainer={true}
    >
      {disabled ? (
        <img
          style={{ opacity: "0.4", filter: "alpha(opacity=40)" }}
          src={imgSrc}
        />
      ) : (
        <img src={imgSrc} />
      )}
    </OptionContainer>
  );
};

export const PlayerTextOption = (props: PlayerOptionProps) => {
  const { children, onClick, style, disabled } = props;
  return (
    <OptionContainer disabled={disabled} style={style} onClick={onClick}>
      <div id={"content"}>
      {children && children}
      </div>
      {disabled && <WrongBadge />}
    </OptionContainer>
  );
};

const BackButtonWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5px 20px 20px;

  span {
    color: white;
  }
`;

const ActionBox = styled.div `
  position: absolute;
  top: 0;
  left: 0px;
  font-size: 0.75em;
  background: #b89fdd63;
  background: linear-gradient(90deg, rgba(184,159,221,0.6474790599833684) 0%, rgba(184,159,221,0) 69%);
  padding-left: 30px;
  right: 0px;
  text-align: left;
  padding-bottom: 0px;
  margin: 0px;
  line-height: 1.2em;
  padding-top: 8px;
`

export const SingleBackButton = (prop:{onClickToLeave}) => {
  const { t } = useTranslation();
  return <BackButtonWrapper>
    <NavigationButton
        onClick={prop.onClickToLeave}
        label={t("gameLocation.backToCity")}
        iconComponent={<UndoIcon fontSize="large" />}
    />
  </BackButtonWrapper>
}

const PlayerInputArea = (props: {
  languageLevel: LanguageLevel;
  inputNodes: StageNode[];
  onInputClick: (node: StageNode) => void;
  onClickToLeave: () => void;
}) => {
  const { inputNodes, onInputClick, onClickToLeave, languageLevel } = props;
  const [inputNodesClone, setInputNodesClone] = useState<StageNode[]>([]);
  const [playerInputOptions, setPlayerInputOptions] = useState<StageNode[]>([]);
  const [delay, setDelay] = useState<number>(1200);
  const userDataService = useUserDataService();
  const userCustomData = userDataService.getCustomData();
  const playingCity = useAppSelector(getPlayingCity);
  const { t } = useTranslation();

  useEffect(() => {
    setPlayerInputOptions([]);
    const clone = [...inputNodes];
    setInputNodesClone(clone);

    setDelay(1200);
  }, [inputNodes]);

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setDelay(400);
      if (inputNodesClone.length) {
        playerInputOptions.push(inputNodesClone.shift()!);
        setPlayerInputOptions([...playerInputOptions]);
        setInputNodesClone([...inputNodesClone]);
      }
    }, delay);

    return () => {
      clearTimeout(timer1);
    };
  }, [inputNodesClone]);

  function buildPlayerInput() {
    //console.log("playerInputOptions", playerInputOptions);

    return (
      <PlayerOptions>
        {playerInputOptions.map((stageNode) => {
          const styles = {};
          function getTextFromInputNode(): string {
            const data: PlayerData = stageNode.questNode.data;
            const languageText = getLanguageText(languageLevel, data)
            return getGenderMessage(languageText, userCustomData?.gender);
          }

          switch (stageNode.questNode.type) {
            case NodeType.objectChoice:
              return (
                <PlayerImgOption
                  style={styles}
                  key={"sn_" + stageNode.questNode.id}
                  onClick={() => onInputClick(stageNode)}
                  imgSrc={getUrlFromAsset(stageNode.questNode.data.objectAsset)}
                />
              );
            case NodeType.puzzle:
              return (
                <InputPuzzle
                  languageLevel={languageLevel}
                  style={styles}
                  key={"sn_" + stageNode.questNode.id}
                  stageNode={stageNode}
                  onRightAnswer={() => onInputClick(stageNode)}
                  onWrongAnswer={() => console.log("WRONG")}
                />
              );
            default:
              const text = getTextFromInputNode();
              const paddingTop = (stageNode.questNode.data.actionNode)? "20px":"0px";
              const getActionName = () => {
                switch (playingCity?.language.toString().toLowerCase()) {
                  case "spanish":
                    return "¡Acción!";
                  case "german":
                    return "Aktion!"
                  case "portuguese":
                    return "Ação";
                  case "english":
                    return "Action";
                  case "french":
                    return "Action";
                  default:
                    return "Action"
                }
              }
              return (
                <PlayerTextOption
                  key={"sn_" + stageNode.questNode.id}
                  onClick={() => onInputClick(stageNode)}
                  style={{paddingTop:paddingTop}}
                >
                  {stageNode.questNode.data.actionNode && <ActionBox>{getActionName()}</ActionBox>}
                  {text}
                </PlayerTextOption>
              );
          }
        })}
      </PlayerOptions>
    );
  }

  const playerInput = buildPlayerInput();

  return (
    <Wrapper>
      {playerInput}
      <SingleBackButton onClickToLeave={onClickToLeave} />
    </Wrapper>
  );
};

export default PlayerInputArea;
