import styled from "styled-components";

export const Logo = styled.span<{ $overMap: boolean }>`
  font-family: "Saira Condensed";
  font-style: normal;
  font-weight: 800;
  font-size: 1.5em;
  line-height: 41px;
  color: #b37c36;
  display: flex;
  flex-flow: column;
  text-align: center;
  padding-top: 10px;

  img {
    filter: saturate(0.8);
    object-fit: contain;
    padding: 40px 0px;
    height: 150px;
  }
  span {
    margin-top: -40px;
    min-height: 41px;
    border-bottom: ${(props) => (props.$overMap ? "1px solid orange" : "")};
  }
`;
