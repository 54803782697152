import styled from "@emotion/styled";
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Carousel from "react-material-ui-carousel";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import ReactMarkdown from "react-markdown";
import {
  useMongoDB,
  UserCustomData,
  UserCustomDataDAL,
  useRealmApp,
} from "@pal/common";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import {
  getUserCustomData,
  setUserCustomData,
} from "../slices/gameSystemSlice";
import {Button, Divider, Paper} from "@mui/material";
import GlobeCities from "./GlobeCities";
import { useUserDataService } from "../services/UserDataService";
import LanguageSwitch from "../components/common/LanguageSwitch";
import OriginalLanguageSwitch from "../components/common/OriginalLanguageSwitch";
import { useTranslation } from 'react-i18next';

const Wrapper = styled.div`
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  flex: 1 1 auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
      0deg,
      rgb(255, 223, 163) 0%,
      rgba(255, 255, 255, 0) 75%
  );
`;
const MyWelcomePaper = styled.div`
  width: 90%;
  margin: auto;
  max-width: 500px;
  padding: 0px;
  border-radius: 20px;
  height:600px;
  border: 1px solid orange;
  background: white;
  opacity: 1 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  
  h2 {
    background: orange;
    margin: 0;
    width: 100%;
    padding: 20px;
    color: white;
  }
  p {
    padding: 0px 20px;
  }
`;
const MyCaurosel = styled(Carousel)`
  width: 100%;
  height: 650px !important;
  .makeStyles-itemWrapper-3 {
    height: 600px !important;
  }
  .makeStyles-buttonHidden-12 {
    opacity: 0.5;
  }
}
`;

const WelcomeScreen = () => {
  // @ts-ignore
  const { user } = useRealmApp();
  const { userDB } = useMongoDB();
  const authed = user && user.state === "active";
  const signUpData = useAppSelector(getUserCustomData);
  const dispatch = useAppDispatch();
  const history = useHistory();
  const userDataService = useUserDataService();
  const [welcomeSlideIndex, setWelcomeSlideIndex] = useState<number|undefined>(0)
  const { t } = useTranslation();

  const enum SyncProgress {
    waiting,
    uploading,
    done,
  }
  const [userDataSyncCurrentPhase, setUserDataSyncCurrentPhase] =
    useState<SyncProgress>(SyncProgress.waiting);

  async function asyncSaveSignUpData(userCustomData: UserCustomData) {
    if (userDataSyncCurrentPhase > SyncProgress.waiting) {
      return; //ignore if already uploading or done
    }
    const response = await UserCustomDataDAL.save(userCustomData);

    dispatch(
      setUserCustomData(
        Object.assign(userCustomData, { _id: response.insertedId.toString() })
      )
    );
  }

  useEffect(() => {
    if (user && userDB && authed && signUpData && !signUpData._id) {
      setUserDataSyncCurrentPhase(SyncProgress.uploading);
      let userDataClone = { ...signUpData };
      asyncSaveSignUpData(Object.assign(userDataClone, { userId: user.id }));
    }
  }, [user, userDB, signUpData, authed]);

  const items = [
    {
      name: 'tutorial.welcomeTitle',
      description:'tutorial.welcomeDescription',
      customForm: <Paper sx={{width:"80%", p:2, margin:"auto", display:"flex", flexFlow:"column", justifyContent:"center"}}>
          <OriginalLanguageSwitch />
        </Paper>
    },
    {
      name: 'tutorial.learnLevelTitle',
      description: 'tutorial.learnLevelDescription',
      customForm: <Paper sx={{width:"80%", p:2, margin:"auto", display:"flex", flexFlow:"column", justifyContent:"center"}}>
        <LanguageSwitch language={"es"} showLabel={false}/>
        <Divider sx={{width:"90%", margin:"20px auto"}} />
        <LanguageSwitch language={"en"} showLabel={false} />
      </Paper>
    },
    {
      name: 'tutorial.navigateTitle',
      description: 'tutorial.navigateDescription',
      video: "https://lq-gc-graphics.s3.amazonaws.com/welcome1_AT8.mp4",
    },
    {
      name: 'tutorial.questTitle',
      description: 'tutorial.questDescription',
      video: "https://lq-gc-graphics.s3.amazonaws.com/welcome-slide-4_AT8.mp4",
    },
    {
      name: 'tutorial.discoverTitle',
      description: 'tutorial.discoverDescription',
      video: "https://lq-gc-graphics.s3.amazonaws.com/welcome-slide-5_AT8.mp4",//video: "https://lq-gc-graphics.s3.amazonaws.com/welcome2_AT8.mp4",
    },
    {
      name: 'tutorial.helpTitle',
      description: 'tutorial.helpDescription',
      video: "https://lq-gc-graphics.s3.amazonaws.com/welcome-slide-6_AT8.mp4",
    },
    {
      name: 'tutorial.feedbackTitle',
      description: 'tutorial.feedbackDescription',
      end: true,
    },
  ];

  function Item(props) {

    return (
      <MyWelcomePaper>
        <h2>{t(props.item.name)}</h2>
        <ReactMarkdown children={t(props.item.description)} />
        {props.item.customForm && (
            props.item.customForm
          )}
        <div style={{width:"100%"}}>
          <Button sx={{float:"right", margin:"5px 0px", marginRight:"30px", border:"1px solid gray"}} onClick={() => props.item.end? history.push("/") : setWelcomeSlideIndex(welcomeSlideIndex? welcomeSlideIndex+1:1)}>
            {props.item.end? t("tutorial.start.playing") : t("tutorial.next.page")}
          </Button>
          {!props.item.end && <Button sx={{float:"left", margin:"5px 0px", marginLeft:"30px", border:"1px solid gray"}} onClick={() => history.push("/")}>
          {t("tutorial.skip")}
          </Button>}

        </div>
        <div>
        {props.index === welcomeSlideIndex && <video style={{width: "100%", maxHeight:'100%'}} autoPlay loop playsInline>
          <source src={props.item.video} type="video/webm"/>
        </video>}
        </div>
      </MyWelcomePaper>
    );
  }

  return (
    <>
      <Wrapper>
        <MyCaurosel
          NextIcon={<NavigateNextIcon />}
          PrevIcon={<NavigateBeforeIcon />}
          interval={60*1000}
          onChange={(now) => setWelcomeSlideIndex(now)}
          index={welcomeSlideIndex}
        >
          {items.map((item, i) => (
            <Item key={i} item={item} index={i}/>
          ))}
          {}
        </MyCaurosel>
      </Wrapper>
    </>
  );
};

export default WelcomeScreen;
