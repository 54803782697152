import {BSON} from "realm-web";
import {BaseModel, getDBInstance} from "@pal/common";
import {getUserDBInstance} from "../providers/mongodb";


class BaseDAL<T extends BaseModel> {

    collectionName: string;
    userData: boolean;

    constructor(collectionName: string, userData:boolean=false) {
        this.collectionName = collectionName;
        this.userData = userData;
    }

    getDB = () => {
        return (!this.userData) ? getDBInstance() : getUserDBInstance();
    }
    fetch = async (filter:string="", filterField:string="name") => {
        const db = this.getDB()

        if (db) {
            const collection = db.collection(this.collectionName);
            let filterObject: {[key: string]: { $regex: string; $options: string; }} = {};
            filterObject[filterField] ={$regex : filter, '$options' : 'i'};
            const responseArray:T[] = await collection.find((filter!=="") ? filterObject : {});
            responseArray.map((t)=>t._id = t._id?.toString()); //map all _id to string for redux.
            return responseArray;
        } else {
            console.log("Undefined DB")
        }
        let empty: T[] = [];
        return empty;
    }

    fetchWithFilterObject = async (filterObject:any) => {
        const db = this.getDB()

        if (db) {
            const collection = db.collection(this.collectionName);
            const responseArray:T[] = await collection.find((filterObject!) ? filterObject : {});
            responseArray.map((t)=>t._id = t._id?.toString()); //map all _id to string for redux.
            return responseArray;
        } else {
            console.log("Undefined DB")
        }
        let empty: T[] = [];
        return empty;
    }

    fetchAggregate = async (aggregationPipeline: any[]) => {
        const db = this.getDB()

        if (db) {
            const collection = db.collection(this.collectionName);
            const responseArray:T[] = await collection.aggregate(aggregationPipeline);
            responseArray.map((t)=>t._id = t._id?.toString()); //map all _id to string for redux.
            return responseArray;
        } else {
            console.log("Undefined DB")
        }
        let empty: T[] = [];
        return empty;
    }

    save = async (t: T, upsert =false) => {
        const db = this.getDB()
        console.log("db", db);
        if (db) {
            const collection = db.collection(this.collectionName);
            let response;
            if (t._id === null || t._id === undefined || t._id === "") {
                let objectToSave:any = {...t};
                delete objectToSave._id;
                response = await collection.insertOne(objectToSave);
            }  else {
                let a:any = {...t};
                //convert back to ObjectID
                let oid = new BSON.ObjectId(t._id);
                a._id = oid;
                const query = { "_id": a._id};
                const update = {"$set": a};
                const options = { "upsert": upsert }; // insert if there's nothing to update
                console.log("on save asset", a, update)
                response = await collection.updateOne(query, update, options);


            }
            return response;
        }
    }

    delete = async (t:T) => {
        const db = this.getDB()
        if (db) {
            const collection = db.collection(this.collectionName);
            //convert back to ObjectID
            let oid = new BSON.ObjectId(t._id!);
            const query = {"_id": oid};

            const response = await collection.deleteOne(query);

            return response;
        }
    }
}

export default BaseDAL;
