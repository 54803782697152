import {FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";
import React, {useState} from "react";
import {MyInputProps} from "./LoginForm";
import {Visibility, VisibilityOff} from "@mui/icons-material";

export const PasswordInput = (props:MyInputProps) => {
    const {id, label, onChange, onInputKeyDown, value} = props;
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    };

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    return <FormControl sx={{ width: '90%', margin:"6px auto", color:"#000" }} variant="outlined">
        <OutlinedInput id={id}
                       type={!showPassword ? "password" : ""}
                       onKeyDown={(event)=> onInputKeyDown && onInputKeyDown(event)}
                       onChange={onChange}
                       sx={{background:"#fff"}}
                       value={value}
                       label={label}
                       endAdornment={
                           <InputAdornment position="end">
                               <IconButton
                                   aria-label="toggle password visibility"
                                   onClick={handleClickShowPassword}
                                   onMouseDown={handleMouseDownPassword}
                                   edge="end"
                                   tabIndex={-1}
                               >
                                   {showPassword ? <VisibilityOff /> : <Visibility />}
                               </IconButton>
                           </InputAdornment>
                       }
        />
        <InputLabel htmlFor={id}>{label}</InputLabel>
    </FormControl>
}
