import {Autocomplete, Box, TextField} from "@mui/material";
import {availableLanguageForTranslation} from "../game/TranslationLanguagePicker";
import {getTranslationLanguage, setTranslationLanguage} from "../../slices/userGamePlaySlice";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../app/hooks";

const OriginalLanguageSwitch = () => {
    const originalLanguage = useAppSelector(getTranslationLanguage);
    const dispatch = useAppDispatch();
    return (
        <Autocomplete
            disablePortal
            id="trasnlationLanguagePicker"
            options={availableLanguageForTranslation}
            sx={{ minWidth: "200px" }}
            value={originalLanguage}
            onChange={(_, newValue)=> newValue ? dispatch(setTranslationLanguage(newValue!)) : dispatch(setTranslationLanguage(""))}
            renderInput={(params) => <TextField {...params} label="" />}
        />)
}

export default OriginalLanguageSwitch
