import React, { useEffect, useState } from "react";
import CityMap from "./screens/CityMap";
import "./style/fonts.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import {
  ConfirmEmail,
  ForgotPassword,
  LoginForm,
  PrivateRoute,
  ResetPassword,
  UserCustomData,
  useRealmApp,
} from "@pal/common";
import styled from "@emotion/styled";
import { gameTheme } from "./style/gametheme";
import GlobeCities from "./screens/GlobeCities";
import Header from "./components/layout/Header";
import {css, CssBaseline, ThemeProvider} from "@mui/material";
import { Global } from "@emotion/react";
import GameLocationStage from "./screens/GameLocationStage";
import logo from "./assets/pallogo.png";
import WelcomeScreen from "./screens/WelcomeScreen";
import { setUserCustomData } from "./slices/gameSystemSlice";
import { useAppDispatch } from "./app/hooks";

/*

*,
          *::before,
          *::after {
            box-sizing: border-box;
          }
          html {
            padding: 0px;
          }
          body {
            background: ${props => "#fff"};
            font-family: 'Saira Condensed';
            height: 100%;
            color: ${props => props.theme.palette.primary.main};
          }

          div, p, span, li {
            color: ${props => props.theme.palette.primary.main};
          }

          button {
            font-family: 'Saira Condensed';
          }
 */

const GlobalStyle = (props) => (
  <Global
    {...props}
    styles={css`
      body {
        font-family: "Saira Condensed";
        height: 100%;
      }
    `}
  />
);

const windowHeight = window.innerHeight;

const AppContainer = styled.div<{ $height: number }>`
  height: 100vh; /* Fallback for browsers that do not support Custom Properties */
  height: calc(var(--vh, 1vh) * 100);
  max-height: fit-content;
  overflow-x: hidden;
  width: 100vw;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    width: 80%;
    left: 10%;
    margin: auto;
    bottom: 0;
    z-index: -1;
    background: linear-gradient(
        0deg,
        rgb(255, 255, 255) 0%,
        rgba(255, 229, 178, 0) 10%,
        rgba(255, 206, 110, 0) 90%,
        rgb(255, 255, 255) 100%
      ),
      url(${logo}) no-repeat center center;
    background-size: contain;
    opacity: 20%;

    @media (min-width: 850px) {
      width: 40%;
      left: 30%;
    }
  }
`;

function App() {
  const { user } = useRealmApp();
  const authed = user && user.state === "active";
  const dispatch = useAppDispatch();
  const [signUpData, setSignUpData] = useState<UserCustomData | null>(null);

  useEffect(() => {
    window.addEventListener("resize", function () {
      // @ts-ignore
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  });

  useEffect(() => {
    if (user && authed && signUpData) {
      dispatch(setUserCustomData(signUpData!));
      setSignUpData(null);
      window.location.href = "/welcome";
    }
  }, [user, authed, signUpData]);

  const signUpCallBack = (userCustomData: UserCustomData) => {
    setSignUpData(userCustomData);
  };

  return (
    <ThemeProvider theme={gameTheme}>
      <CssBaseline />
      <GlobalStyle />
      <Router>
        <Header></Header>
        <Switch>
          <AppContainer id={"app"} $height={windowHeight}>
            <Route key={"signup"} path="/signup">
              <LoginForm
                enableSignUp={true}
                signUpIntent={true}
                signUpCallback={signUpCallBack}
              />
            </Route>
            <Route key={"forgotPassword"} path="/forgot-password">
              <ForgotPassword />
            </Route>
            <PrivateRoute
              enableSignUp={true}
              signUpCallback={signUpCallBack}
              key={"confirmEmail"}
              authed={authed}
              path="/confirm-email"
            >
              <ConfirmEmail />
            </PrivateRoute>
            <Route key={"resetPassword"} path="/reset-password">
              <ResetPassword />
            </Route>
            <PrivateRoute
              enableSignUp={true}
              signUpCallback={signUpCallBack}
              key={"WelcomeScreen"}
              authed={authed}
              path="/welcome"
            >
              <WelcomeScreen />
            </PrivateRoute>
            <PrivateRoute
              enableSignUp={true}
              signUpCallback={signUpCallBack}
              key={"gameLocation"}
              authed={authed}
              path="/gamelocation"
            >
              <GameLocationStage />
            </PrivateRoute>
            <PrivateRoute
              enableSignUp={true}
              signUpCallback={signUpCallBack}
              key={"city"}
              authed={authed}
              path="/city"
            >
              <CityMap />
            </PrivateRoute>
            <PrivateRoute
              enableSignUp={true}
              signUpCallback={signUpCallBack}
              key={"default"}
              authed={authed}
              path="/"
              exact={true}
            >
              <GlobeCities />
            </PrivateRoute>
          </AppContainer>
        </Switch>
      </Router>
    </ThemeProvider>
  );
}

export default App;
