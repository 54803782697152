import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

interface IResources {
    [key: string]: {
        translation: {
            [key: string]: string;
        };
    };
}

const resources: IResources = {
    en: {
        translation: {
            //game
            "game.you":"you",

            // questCompleted
            "questCompleted.completed": "Quest completed!",
            "questCompleted.next":"Next!",

            //home
            "home.chooseCity":"Where do you want to learn?",
            "home.comingSoon":"Coming soon...",

            //navigation
            "nav.home":"Other cities",
            "nav.skills":"Skills",
            "nav.achievements":"Achievements",
            "nav.practice":"Practice",

            // user menu
            "userMenu.reviewTutorial":"Repeat tutorial 👩‍🏫",
            "userMenu.translationLanguage":"Translation language",
            "userMenu.wipeData":"Reset app",
            "userMenu.logout":"Logout",

            //quest manager
            "questM.noActiveQuest":"No active quest",
            "questM.next":"Next quest:",
            "questM.new":"New",
            "questM.current":"Current",
            "questM.completed":"Completed",
            "questM.openQuests":"Open quests",
            "questM.completedQuests":"Completed quests",
            "questM.quests":"quests",
            "questM.activate":"Activate",
            "questM.active":"Active",
            "questM.replay": "Replay",
            "questM.reviewGrammar": "Grammar overview",

            //gameLocation screen
            "gameLocation.backToCity":"Back to city",

            //puzzles
            "puzzle.chooseTheRight":"Choose the right option.",
            "puzzle.advanceNext":"Next",

            //tutorial screen
            "tutorial.next.page": "Next hint",
            "tutorial.skip": "Skip tutorial",
            "tutorial.start.playing" : "Start playing now!",

            'tutorial.welcomeTitle': 'Welcome to Play A Language!',
            'tutorial.welcomeDescription': 'Please select your **preferred translation language**, for example your native language.',

            'tutorial.learnLevelTitle': 'Learn at the right level!',
            'tutorial.learnLevelDescription': 'Please take a moment to select the **level of each language** you are learning.',

            'tutorial.navigateTitle':"Navigating the map!",
            'tutorial.navigateDescription':`After selecting a city on the initial menu, you will see your learning environment from above.    
              Zoom in or out, and click to enter any of the **locations** that appear on the map.`,

            'tutorial.questTitle': "Find your next quest!",
            'tutorial.questDescription':`The **quest bar** at the bottom of the map shows the next available quests and their language topics. Mark a quest as active to see where it starts.`,

            'tutorial.discoverTitle': "Discover the story!",
            'tutorial.discoverDescription':`Inside locations, you will hear messages from the narrator and the characters spoken aloud. Please turn on the *audio*.  
            To give answers and interact with the story, you can choose from several **options** or solve a **language puzzle**.    
            🎧 Please activate your audio!`,

            'tutorial.helpTitle': "Get help!",
            'tutorial.helpDescription': `If you don't understand something, ask for help:  
            Look at the **picture box** (beginner level) or press the **hint icon** (intermediate & advanced level).  
            Translate text by hitting the **translation button**.`,

            'tutorial.feedbackTitle':"Help us improve!",
            'tutorial.feedbackDescription':`This is a very **early alpha release**. You're likely to find bugs and things that don't work.  
            Please **help us improve**: report bugs, missing features and new ideas to [hello@playalanguage.com](mailto:hello@playalanguage.com).    
            **Thank you!**`,
        }
    },
    pt: {
        translation: {
            //game
            "game.you":"you",

            // questCompleted
            "questCompleted.completed": "Quest completed!",
            "questCompleted.next":"Next!",

            //home
            "home.chooseCity":"Onde voce quer aprender?",
            "home.comingSoon":"Em breve",

            "questM.noActiveQuest": "Nenhuma missão ativa",
            "questM.next": "Próxima",
            "questM.new": "Nova",
            "questM.current": "Abertas",
            "questM.completed": "Concluídas",
            "questM.openQuests": "Missões abertas",
            "questM.completedQuests": "Missões concluídas",
            "questM.quests": "Missões",
            "questM.activate": "Ativar",
            "questM.active": "Ativa",
            "questM.replay": "Repetir",
            "questM.reviewGrammar": "Revisar gramática?",

            //navigation
            "nav.home": "Outras cidades",
            "nav.skills": "Habilidades",
            "nav.achievements": "Conquistas",
            "nav.practice": "Praticar",

            //user menu
            "userMenu.reviewTutorial": "Revisar tutorial 👩‍🏫",
            "userMenu.translationLanguage": "Traduzir textos para",
            "userMenu.wipeData": "Apagar dados",
            "userMenu.logout": "Encerrar sessão",

            //gameLocation
            "gameLocation.backToCity": "Voltar para o mapa",

            //puzzles
            "puzzle.chooseTheRight": "Escolha a opção correta",
            "puzzle.advanceNext": "Próximo",

            //tutorial screen
            "tutorial.next.page": "Próxima dica",
            "tutorial.skip": "Pular tutorial",
            "tutorial.start.playing" : "Comece a jogar!",

            'tutorial.welcomeTitle': 'Bem-vindo ao Play A Language!',
            'tutorial.welcomeDescription': 'Por favor, escolha o seu **idioma de tradução** preferido, por exemplo, a sua língua materna.',

            'tutorial.learnLevelTitle': 'Aprenda no nível certo!',
            'tutorial.learnLevelDescription': 'Tire um momento para selecionar o **nível de cada idioma** que está aprendendo.',

            'tutorial.navigateTitle':"Navegue pelo mapa!",
            'tutorial.navigateDescription':`Depois de selecionar uma **cidade** no menu inicial, você verá o mapa da cidade de cima.   
            Aproxime-se ou afaste-se (similar a outras aplicações com mapas), e clique em um dos **locais** no mapa para entrar.`,

            'tutorial.questTitle': "Encontre a sua próxima missão!",
            'tutorial.questDescription':`A **barra de missões** na parte inferior do mapa mostra as próximas missões disponíveis e seus temas de idioma. **Selecione** uma missão como **ativa** para ver qual o próximo passo.`,

            'tutorial.discoverTitle': "Descubra a história!",
            'tutorial.discoverDescription':`Nos locais, você ouvirá as notícias do narrador e dos personagens lidos em voz alta. Por favor, ligue o áudio.  
            Para dar respostas e interagir com a história, você pode escolher entre **várias opções** ou resolver um **quebra-cabeça de idiomas**.  
            🎧 Por favor, ligue o seu áudio!`,

            'tutorial.helpTitle': "Obtenha ajuda!",
            'tutorial.helpDescription': `Se você não entender algo, peça ajuda:  
            Olhe na caixa de imagens (nível iniciante) ou pressione o ícone de **dica**.  
            Traduza o texto pressionando o **botão de tradução**.`,

            'tutorial.feedbackTitle':"Ajude-nos a melhorar!",
            'tutorial.feedbackDescription':`Esta é uma versão alfa muito preliminar. É provável que você encontre erros e coisas que não funcionam.  
            Por favor, ajude-nos a melhorar: relate os erros, as funções que faltam e as novas ideias para [hello@playalanguage.com](mailto:hello@playalanguage.com).  
            **Muito obrigado!**`,
        }

    },
    es: {
        translation: {
            //game
            "game.you":"you",

            // questCompleted
            "questCompleted.completed": "Quest completed!",
            "questCompleted.next":"Next!",

            //home
            "home.chooseCity": "¿Dónde quieres aprender?",
            "home.comingSoon": "Próximamente disponible...",

            //quest manager
            "questM.noActiveQuest": "No hay tareas activas.",
            "questM.next": "Próxima tarea:",
            "questM.new": "Nuevo",
            "questM.current": "Pendiente",
            "questM.completed": "Completado",
            "questM.openQuests": "Tareas pendientes",
            "questM.completedQuests": "Tareas completadas",
            "questM.quests": "Tareas",
            "questM.activate": "Activar",
            "questM.active": "Activo",
            "questM.replay": "Repetir",
            "questM.reviewGrammar": "Resumen gramatical",

            //navigation
            "nav.home": "Otras ciudades",
            "nav.skills": "Capacidades",
            "nav.achievements": "Logros",
            "nav.practice": "Prácticas",

            //user menu
            "userMenu.reviewTutorial": "Repetir tutorial 👩‍🏫",
            "userMenu.translationLanguage": "Idioma de traducción",
            "userMenu.wipeData": "Restablecer aplicación",
            "userMenu.logout": "Cerrar sesión",

            //gameLocation
            "gameLocation.backToCity": "Volver a ciudad",

            //puzzles
            "puzzle.chooseTheRight": "Elige la opción correcta.",
            "puzzle.advanceNext": "Siguiente",

            //tutorial screen
            "tutorial.next.page": "Siguiente",
            "tutorial.skip": "Omitir tutorial",
            "tutorial.start.playing" : "¡Comienza a jugar!",

            'tutorial.welcomeTitle': '¡Bienvenid@ a Play A Language!',
            'tutorial.welcomeDescription': 'Por favor, elige tu **idioma de traducción preferido**, por ejemplo, tu lengua materna.',

            'tutorial.learnLevelTitle': '¡Aprende al nivel correcto!',
            'tutorial.learnLevelDescription': 'Tómate un momento para seleccionar el **nivel de cada idioma** que estás aprendiendo.',

            'tutorial.navigateTitle':"¡Navega por el mapa!",
            'tutorial.navigateDescription':`Después de seleccionar una ciudad en el menú inicial, verás tu entorno de aprendizaje desde arriba.    
              Amplia o reduce el mapa y haz clic para entrar en uno de los **lugares**.`,

            'tutorial.questTitle': "¡Encuentra tu próxima tarea!",
            'tutorial.questDescription':`La **barra de tareas** situada en la parte inferior del mapa muestra las próximas tareas disponibles y sus enfoques lingüísticos. Marca una tarea como activa para ver en qué lugar empieza.`,

            'tutorial.discoverTitle': "¡Descubre la historia!",
            'tutorial.discoverDescription':`En los lugares, escucharás los mensajes del narrador y de los personajes en voz alta. Por favor, enciende el *audio*.  
            Para dar respuestas y interactuar con la historia, puedes elegir entre varias **opciones** o resolver un **ejercicio**.  
            🎧 ¡Por favor, enciende el audio!`,

            'tutorial.helpTitle': "¡Pide ayuda!",
            'tutorial.helpDescription': `Si no entiendes algo, pide ayuda:  
            Mira la *imagen que representa una de las palabras* (nivel principiante) o presiona el *icono de ayuda* (nivel medio & avanzado).
            Traduce cualquier texto presionando el *icono de traducción*.`,

            'tutorial.feedbackTitle':"¡Ayúdanos a mejorar!",
            'tutorial.feedbackDescription':`Esta es una **primera versión alfa de nuestra aplicación**. Es probable que te encuentres con errores y cosas que no funcionan.
            Por favor, **ayúdanos a mejorar**: infórmanos de los errores, las funciones que faltan y las nuevas ideas a [hello@playalanguage.com](mailto:hello@playalanguage.com).  
            **¡Muchas gracias!**`,
        }
    },

    fr: {
        translation: {
            //game
            "game.you":"you",

            // questCompleted
            "questCompleted.completed": "Quest completed!",
            "questCompleted.next":"Next!",

            //home
            "home.chooseCity": "Choisissez un endroit",
            "home.comingSoon": "Bientôt disponible",

            //quest manager
            "questM.noActiveQuest": "Pas de quête active",
            "questM.next": "Suivant",
            "questM.new": "Nouveau",
            "questM.current": "Ouvertes",
            "questM.completed": "Terminé",
            "questM.openQuests": "Quêtes ouvertes",
            "questM.completedQuests": "Quêtes terminées",
            "questM.quests": "Quêtes",
            "questM.activate": "Activer",
            "questM.active": "Actif",
            "questM.replay": "Rejouer",
            "questM.reviewGrammar": "Réviser la grammaire?",

            //navigation
            "nav.home": "Autres villes",
            "nav.skills": "Compétences",
            "nav.achievements": "Réalisations",
            "nav.practice": "Pratique",

            //user menu
            "userMenu.reviewTutorial": "Revoir le tutoriel 👩‍🏫",
            "userMenu.translationLanguage": "Traduire les textes en",
            "userMenu.wipeData": "Effacer les données",
            "userMenu.logout": "Se déconnecter",

            //gameLocation
            "gameLocation.backToCity": "Retour à la ville",

            //puzzles
            "puzzle.chooseTheRight": "Choisissez la bonne option",
            "puzzle.advanceNext": "Suivant",

            //tutorial screen
            "tutorial.next.page": "Prochain",
            "tutorial.skip": "Passer le tutoriel",
            "tutorial.start.playing" : "Commencez à jouer maintenant!",

            'tutorial.welcomeTitle': 'Bienvenue sur Play A Language!',
            'tutorial.welcomeDescription': 'Veuillez sélectionner votre **langue de traduction préférée**, par exemple votre langue maternelle.',

            'tutorial.learnLevelTitle': 'Apprenez au bon niveau!',
            'tutorial.learnLevelDescription': 'Prenez un moment pour sélectionner le **niveau de chaque langue** que vous apprenez.',

            'tutorial.navigateTitle':"Naviguer sur la carte!",
            'tutorial.navigateDescription':`Après avoir sélectionné une ville sur le menu initial, vous verrez votre environnement d'apprentissage vu d'en haut.      
              Zoomez ou dézoomez et cliquez pour entrer dans n'importe lequel des **lieux** qui apparaissent sur la carte.`,

            'tutorial.questTitle': "Trouvez votre prochaine quête!",
            'tutorial.questDescription':`La **barre de quête** en bas de la carte affiche les prochaines quêtes disponibles et leurs sujets linguistiques.  
            Marquez une quête comme active pour voir où elle commence.`,

            'tutorial.discoverTitle': "Découvrez l'histoire!",
            'tutorial.discoverDescription':`Dans les lieux, vous entendrez les messages du narrateur et des personnages parlés à haute voix. Veuillez activer l'*audio*.  
            Pour donner des réponses et interagir avec l'histoire, vous pouvez choisir parmi plusieurs **options** ou résoudre un **puzzle de langue**.  
            🎧 Veuillez activer votre audio!`,

            'tutorial.helpTitle': "Obtenez de l'aide!",
            'tutorial.helpDescription': `Si vous ne comprenez pas quelque chose, demandez de l'aide:  
            Regardez la *boîte à images* (niveau débutant) ou appuyez sur l'*icône d'indice* (niveau intermédiaire & avancé).
            Traduisez le texte en appuyant sur le *bouton de traduction*.`,

            'tutorial.feedbackTitle':"Aidez-nous à nous améliorer!",
            'tutorial.feedbackDescription':`C'est une **version alpha très préliminaire**. Il est probable que vous rencontriez des bugs et des choses qui ne fonctionnent pas.
            Veuillez **nous aider à nous améliorer**: signalez les bugs, les fonctionnalités manquantes et les nouvelles idées à [hello@playalanguage.com](mailto:hello@playalanguage.com).  
            **Merci!**`,
        }
    },
    de: {
        translation: {
            //game
            "game.you":"you",

            // questCompleted
            "questCompleted.completed": "Quest completed!",
            "questCompleted.next":"Next!",

            //home
            "home.chooseCity": "Wo möchtest Du lernen?",
            "home.comingSoon": "Demnächst verfügbar...",

            //navigation
            "nav.home": "Andere Städte",
            "nav.skills": "Fähigkeiten",
            "nav.achievements": "Erfolge",
            "nav.practice": "Übungen",

            //user menu
            "userMenu.reviewTutorial": "Tutorial wiederholen 👩‍🏫",
            "userMenu.translationLanguage": "Übersetzungssprache",
            "userMenu.wipeData": "App zurücksetzen",
            "userMenu.logout": "Abmelden",

            //quest manager
            "questM.noActiveQuest": "Keine aktiven Quests",
            "questM.next": "Nächster Quest:",
            "questM.new": "Neu",
            "questM.current": "Offen",
            "questM.completed": "Abgeschlossen",
            "questM.openQuests": "Offene Quests",
            "questM.completedQuests": "Abgeschlossene Quests",
            "questM.quests": "Quests",
            "questM.activate": "Aktivieren",
            "questM.active": "Aktiv",
            "questM.replay": "Wiederholen",
            "questM.reviewGrammar": "Grammatik-Übersicht",

            //gameLocations
            "gameLocation.backToCity": "Zurück zur Stadt",

            //puzzles
            "puzzle.chooseTheRight": "Wähle die richtige Option.",
            "puzzle.advanceNext": "Weiter",

            //tutorial screen
            "tutorial.next.page": "Nächster Tipp",
            "tutorial.skip": "Überspringen",
            "tutorial.start.playing" : "Jetzt spielen!",

            'tutorial.welcomeTitle': 'Willkommen bei Play A Language!',
            'tutorial.welcomeDescription': 'Bitte wähle deine **bevorzugte Übersetzungssprache** aus, zum Beispiel deine Muttersprache.',

            'tutorial.learnLevelTitle': 'Lerne auf dem richtigen Niveau!',
            'tutorial.learnLevelDescription': 'Nimm dir einen Moment Zeit, um für jede Sprache, die du lernst, das **richtige Niveau** auszuwählen.',

            'tutorial.navigateTitle':"Navigiere auf der Karte!",
            'tutorial.navigateDescription':`Nachdem du im Startmenü eine Stadt ausgewählt hast, siehst du Deine Lernumgebung von oben.  
              Zoome hinein oder heraus und klicke auf einen **Ort auf der Karte**, um ihn zu betreten.`,

            'tutorial.questTitle': "Finde dein nächstes Quest!",
            'tutorial.questDescription':`Die **Quest-Leiste** am unteren Rand der Karte zeigt dir die nächsten verfügbaren Quests und ihre Sprachthemen an. Markiere einen Quest als aktiv, um zu sehen, an welchem Ort er beginnt.`,

            'tutorial.discoverTitle': "Entdecke die Geschichte!",
            'tutorial.discoverDescription':`Innerhalb der einzelnen Orte sprechen Erzähler und Charaktere ihre Botschaften laut aus. Bitte aktiviere *Audio* auf deinem Gerät.
            Um Antworten zu geben und mit der Geschichte zu interagieren, kannst Du aus verschiedenen **Optionen** wählen oder ein **Sprachpuzzle** lösen.  
            🎧 Bitte schalte dein Audio ein!`,

            'tutorial.helpTitle': "Hole dir Hilfe!",
            'tutorial.helpDescription': `Wenn du etwas nicht verstehst, hole dir einfach Hilfe:  
            Schau auf die *Bildbox* (Anfängerniveau) oder klicke auf das *Hilfe-Symbol* (mittleres & fortgeschrittenes Niveau).
            Übersetze jeden Text, indem du auf das *Übersetzung-Symbol* drückst.`,

            'tutorial.feedbackTitle':"Hilf uns, besser zu werden!",
            'tutorial.feedbackDescription':`Dies ist eine **sehr frühe Alpha-Version unserer App**. Deshalb wirst du wahrscheinlich noch einige Fehler finden oder auf Dinge stoßen, die nicht funktionieren.
            Bitte **hilf uns, besser zu werden**: Melde Fehler, fehlende Funktionen und neue Ideen an [hello@playalanguage.com](mailto:hello@playalanguage.com).  
            **Vielen Dank!**`,
        }
    }
};

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        fallbackLng: "en", // fallback to English if the detected language translations are not available
        //lng:"fr",
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        detection: {
            // options for language detector
            order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
            caches: ['cookie']
        }
    });

export default i18n;
