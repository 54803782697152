import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import React from "react";
import {useUserDataService} from "../../services/UserDataService";
import { LanguageLevel, LearningLanguage } from "@pal/common";
import styled from "@emotion/styled";


const ProfileToggleButton = styled(ToggleButton)`
  width: 60px;
  background: #e6e4ff;
  font-weight: bolder;
  font-size: 1.2em;
  border: 0px;
  position: relative;
  margin: 10px 0px;

  &.Mui-selected {
    color: white;
    background: #ff985a;
  }

  &.Mui-selected:hover {
    color: white;
    background: #ff8a44;
    background: #8f44ff;
  }

  &:hover {
    color: white;
    background: #ff8a44;
    background: #8f44ff;
  }

  & span {
    position: absolute;
    bottom: -20px;
    color: #343434;
    font-size: 0.6em;
  }
`;

const LanguageSwitch = (props: {language: LearningLanguage, showLabel?: boolean}) => {
    const {language, showLabel} = props;
    const userDataService = useUserDataService();
    const gpData = userDataService.getLanguageGameplayData(language);

    const selectNewLanguageLevel = (
        language: LearningLanguage,
        level: LanguageLevel
    ) => {
        if (language && level) {
            userDataService.setLanguageLevel(language, level).then();
        }
    };

    const getLanguageText = (language:LearningLanguage) => {
        switch (language) {
            case "es": return "Mi nivel de español";
            case "en": return "My english level";
            case "de": return "Mein Deutschlevel";
            case "fr": return "Mon niveau d'françois";
            case "pt": return "Meu nível de português";
            default: return "Language level";
        }
    }
    const languageText = getLanguageText(language)
    return<Box sx={{display:"flex", flexFlow:"column", justifyContent:"center"}}>
        <Box sx={{ textAlign: "left", my:1 }}>{languageText}</Box>
            <ToggleButtonGroup
                color="primary"
                value={gpData?.languageLevel || "intermediate"}
                exclusive
                onChange={(_, newValue) => selectNewLanguageLevel(language, newValue)}
                sx={{ justifyContent: "flex-end" }}
            >
                <ProfileToggleButton value="beginner">A {showLabel && <span>Beginner</span>}</ProfileToggleButton>
                <ProfileToggleButton value="intermediate">B {showLabel && <span>Intermediate</span>}</ProfileToggleButton>
                <ProfileToggleButton value="advanced">C {showLabel && <span>Advanced</span>}</ProfileToggleButton>
            </ToggleButtonGroup>
    </Box>;
}

export default LanguageSwitch;
