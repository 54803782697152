import {LanguageText, useRealmApp} from "@pal/common";
import {CharacterDisplay, Wrapper} from "./NpcDialogueBox";
import MessageDisplay from "./MessageDisplay";
import {useUserDataService} from "../../services/UserDataService";

const PlayerCharacterBox = (props: {
  showNext: boolean;
  onNext: () => void;
  message?: LanguageText;
}) => {
    const userDataService = useUserDataService();
    const userCustomData = userDataService.getCustomData();
    // @ts-ignore
    const playerName = userCustomData?.name
  return (
    <Wrapper>
        <div style={{ display: "flex" }}>
            <CharacterDisplay key={"player"} position="left">
                <div style={{marginLeft:"30px"}}>
                    {/*<img style={{}} src={"https://w7.pngwing.com/pngs/686/219/png-transparent-youtube-user-computer-icons-information-youtube-hand-silhouette-avatar-thumbnail.png"} alt={"player avatar"} />*/}
                    <span>{playerName}</span>
                </div>
            </CharacterDisplay>
        </div>
      <MessageDisplay
        color={"#a6caf9"}
        color2={"#9df6ff"}
        bigVariant={true}
        {...props}
      ></MessageDisplay>
    </Wrapper>
  );
};

export default PlayerCharacterBox;
