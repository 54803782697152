import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../app/store";
import {
    ActiveQuest, City, CityLocations, CustomLocationMedia, GameLocation,
    Quest, QuestTrack,
    UserGameplayData,
    UserLanguageGameplayData,
    MapPosition,
} from "@pal/common";

export interface GamePlayState {
    lastCacheTimestamp: number;
    userIdFromGameplayData?: string;
    userGameplayData: UserGameplayData;
    unsavedChanges?: boolean;
    nextLocationId?: string;
    bgAudioSrc?:string;
    voiceAudioSrc?:string;
    sfxAudioSrc?:string;
}

const initialState: GamePlayState = {
    lastCacheTimestamp:-1,
    userIdFromGameplayData: "",
    userGameplayData:{userId:"", _id:"", languageSpecific: [], savedPositions: []}
};

const emptyStringArray:string[] = [];
const emptyArray = [];

export const userGamePlaySlice = createSlice({
    name: "gameplayData",
    initialState,
    reducers: {
        setTranslationLanguage: (state, action: PayloadAction<string>) => {
            state.userGameplayData.translationLanguage = action.payload;
        },
        addTriggeredEvent: (state, action: PayloadAction<string>) => {
            if (state.userGameplayData && !state.userGameplayData.triggeredEvents) {
                state.userGameplayData.triggeredEvents = [];
            }

            if (!state.userGameplayData?.triggeredEvents!.includes(action.payload))
                state.userGameplayData?.triggeredEvents!.push(action.payload);
            state.unsavedChanges = true;
        },
        setNextLocationId: (state, action: PayloadAction<string>) => {
            state.nextLocationId = action.payload;
        },
        setUserGameplayData: (state, action: PayloadAction<UserGameplayData>) => {
            state.userGameplayData = action.payload;
            state.userIdFromGameplayData = state.userGameplayData.userId;
        },
        setUserGameplayDataLanguage: (
            state,
            action: PayloadAction<UserLanguageGameplayData>
        ) => {
            if (!state.userGameplayData) {
                console.error(
                    "Gameplaydata: trying to save language data without base object"
                );
                return;
            }
            const lang = action.payload.language;
            const data = action.payload;

            const newArray =
                state.userGameplayData!.languageSpecific?.filter(
                    (item) => item.language !== lang
                ) || [];
            newArray.push({ language: lang, userLanguageGameplayData: data });
            state.userGameplayData.languageSpecific = newArray;
            state.unsavedChanges = true;
        },
        setLastCacheTimestamp: (state, action: PayloadAction<number>) => {
            state.lastCacheTimestamp = action.payload;
        },
        setPlayingCity: (state, action: PayloadAction<City>) => {
            state.userGameplayData.playingCity = action.payload;
        },
        setPlayingLocation: (state, action: PayloadAction<GameLocation>) => {
            state.userGameplayData.playingLocation = action.payload;
        },
        setQuestTrack: (
            state,
            action: PayloadAction<{ qid: string; questTrack: QuestTrack }>
        ) => {
            if (!state.userGameplayData?.questTracks) {
                state.userGameplayData!.questTracks = [];
            }
            const newArray = state.userGameplayData.questTracks!.filter((element)=>element.qid !== action.payload.qid)
            newArray.push(action.payload.questTrack);
            state.userGameplayData!.questTracks = newArray;
            state.unsavedChanges = true;
        },
        setCustomLocationMedia: (
            state,
            action: PayloadAction<CustomLocationMedia>
        ) => {
            if (!state.userGameplayData.customLocationsMedia) {
                state.userGameplayData.customLocationsMedia = [];
            }
            const newArray = state.userGameplayData.customLocationsMedia.filter((element)=>element.lid !== action.payload.lid);
            newArray.push(action.payload);
            state.userGameplayData.customLocationsMedia = newArray;
            state.unsavedChanges = true;
        },
        setActiveQuestInCity: (
            state,
            action: PayloadAction<ActiveQuest>
        ) => {
            if (!state.userGameplayData.activeQuests) {
                state.userGameplayData.activeQuests = [];
            }
            const newArray = state.userGameplayData.activeQuests.filter((element)=>element.cityCode !== action.payload.cityCode);
            newArray.push(action.payload);
            state.userGameplayData.activeQuests = newArray;
            state.unsavedChanges = true;
        },
        setLastOpenLocationsInCity: (
            state,
            action: PayloadAction<CityLocations>
        ) => {
            if (!state.userGameplayData.lastOpenLocations) {
                state.userGameplayData.lastOpenLocations = [];
            }
            const newArray = state.userGameplayData.lastOpenLocations.filter((element)=>element.cityCode !== action.payload.cityCode);
            newArray.push(action.payload);
            state.userGameplayData.lastOpenLocations = newArray;
            state.unsavedChanges = true;
        },
        setMapPosition: (state, action: PayloadAction<MapPosition>) => {
            if (!state.userGameplayData.savedPositions) {
                state.userGameplayData.savedPositions = [action.payload];
                return;
            }
            const spFiltered = state.userGameplayData.savedPositions.filter(
                (mapPosition) => mapPosition.cityCode !== action.payload.cityCode
            );
            spFiltered.push(action.payload);
            state.userGameplayData.savedPositions = spFiltered;
        },
        purgeQuestGameplayData: (state) => {
            state.userGameplayData.questTracks = [];
            state.userGameplayData.completedQuestsIds = [];
            state.userGameplayData.replayQuestsIds = [];
            state.userGameplayData.lastOpenLocations = [];
            state.userGameplayData.activeQuests = [];
            state.userGameplayData.triggeredEvents = [];
        },
        purgeUserGameplayData: (state) => {
            state.userGameplayData = {userId:"", _id:"", languageSpecific: [], savedPositions: []}
            state.userIdFromGameplayData = "";
        },
        setChangesSaved: (state) => {
            state.unsavedChanges = false;
        },
        addCompletedQuest: (state, action: PayloadAction<string>) => {
            if (state.userGameplayData && !state.userGameplayData.completedQuestsIds) {
                state.userGameplayData.completedQuestsIds = [];
            }
            if (!state.userGameplayData?.completedQuestsIds!.includes(action.payload))
                state.userGameplayData?.completedQuestsIds!.push(action.payload);
            state.unsavedChanges = true;
        },
        replayQuest: (state, action: PayloadAction<string>) => {
            if (state.userGameplayData && !state.userGameplayData.replayQuestsIds) {
                state.userGameplayData.replayQuestsIds = [];
            }
            if (!state.userGameplayData?.replayQuestsIds!.includes(action.payload)) {
                state.userGameplayData?.replayQuestsIds!.push(action.payload);
                const newArray = state.userGameplayData.questTracks!.filter((element)=>element.qid !== action.payload)
                state.userGameplayData!.questTracks = newArray;
                state.unsavedChanges = true;
            }
        },
        finishReplayQuest: (state, action: PayloadAction<string>) => {
            if (state.userGameplayData?.replayQuestsIds?.length) {
                const newArray = state.userGameplayData?.replayQuestsIds!.filter((id)=>id !== action.payload);
                state.userGameplayData!.replayQuestsIds = newArray;
                state.unsavedChanges = true;
            }
        },
        setBgAudioSrc: (state, action: PayloadAction<string>) => {
            state.bgAudioSrc = action.payload;
        },
        setVoiceAudioSrc: (state, action: PayloadAction<string>) => {
            state.voiceAudioSrc = action.payload;
        },

    },
});

export const {
    setTranslationLanguage,
    addTriggeredEvent,
    setNextLocationId,
    addCompletedQuest,
    setPlayingCity,
    setPlayingLocation,
    purgeQuestGameplayData,
    setUserGameplayData,
    setUserGameplayDataLanguage,
    setCustomLocationMedia,
    setLastCacheTimestamp,
    setQuestTrack,
    purgeUserGameplayData,
    setActiveQuestInCity,
    setLastOpenLocationsInCity,
    setChangesSaved,
    replayQuest,
    finishReplayQuest,
    setBgAudioSrc,
    setVoiceAudioSrc,
    setMapPosition,
} = userGamePlaySlice.actions;

export const getTranslationLanguage = (state: RootState) => state.gameplayData.userGameplayData.translationLanguage;
export const getTriggeredEvents = (state: RootState) =>
    (state.gameplayData.userGameplayData) ? state.gameplayData.userGameplayData.triggeredEvents : emptyStringArray;
export const getNextLocationId = (state: RootState) =>
    state.gameplayData.nextLocationId;
export const getLastCacheTimestamp = (state: RootState) =>
    state.gameplayData.lastCacheTimestamp;
export const getPlayingCity = (state: RootState) =>
    state.gameplayData.userGameplayData.playingCity;
export const getPlayingLocation = (state: RootState) =>
    state.gameplayData.userGameplayData.playingLocation;
export const getQuestTracks = (state: RootState) =>
    state.gameplayData.userGameplayData.questTracks || emptyArray;
export const getActiveQuests = (state: RootState) =>
    state.gameplayData.userGameplayData.activeQuests || emptyArray;
export const getCustomLocationsMedia = (state: RootState) =>
    state.gameplayData.userGameplayData.customLocationsMedia || emptyArray;
export const getLastOpenLocations = (state: RootState) =>
    state.gameplayData.userGameplayData.lastOpenLocations || emptyArray;
export const getCompletedQuestsIds = (state: RootState) =>
    state.gameplayData.userGameplayData.completedQuestsIds || emptyStringArray;
export const getReplayQuestsIds = (state: RootState) =>
    state.gameplayData.userGameplayData.replayQuestsIds || emptyStringArray;
export const getUserGameplayData = (state: RootState) =>
    state.gameplayData.userGameplayData;
export const getUserIdFromGameplayData = (state: RootState) =>
    state.gameplayData.userIdFromGameplayData;
export const getUnsavedChanges = (state: RootState) =>
    state.gameplayData.unsavedChanges;
export const getBgAudioSrc = (state: RootState) =>
    state.gameplayData.bgAudioSrc;
export const getVoiceAudioSrc = (state: RootState) =>
    state.gameplayData.voiceAudioSrc;
export const getSfxAudioSrc = (state: RootState) =>
    state.gameplayData.sfxAudioSrc;
export const getSavedPositions = (state: RootState) =>
    state.gameplayData.userGameplayData.savedPositions;

export default userGamePlaySlice.reducer;
