import {
    Autocomplete,
    Box,
    Checkbox,
    createTheme,
    CssBaseline,
    FormControlLabel, FormGroup,
    styled,
    TextField,
    ThemeProvider
} from "@mui/material";
import TranslateIcon from "@mui/icons-material/Translate";
import Button from "@mui/material/Button";
import {useState} from "react";
import {useAppDispatch} from "../../app/hooks";
import {setTranslationLanguage} from "../../slices/userGamePlaySlice";

const PickerWrapper = styled(Box)`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
`
const PickerWindow = styled(Box)`
  min-width: 200px;
  width: 80%;
  height: 150%;
  color: #fff;
  background: #462886;
  background: linear-gradient(180deg, #462886 0%, rgba(111, 75, 208, 1) 100%);
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  justify-content: center;
  z-index: 10;
  padding: 10px;
  border-radius: 5px;
  -webkit-box-shadow: 0px 10px 15px 1px #000000;
  box-shadow: 0px 10px 15px 1px #000000;
  
  h3 {
    padding: 0;
    margin: 5px;
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    max-width: 80%;
  }
  small {
    width: 80%;
    margin-top: 20px;
    font-size: 0.8em;
  }
  svg {
    padding: 5px;
  }
`
export const availableLanguageForTranslation = [
    'BG - Bulgarian',
    'CS - Czech',
    'DA - Danish',
    'DE - German',
    'EL - Greek',
    'EN-GB - English (British)',
    'EN-US - English (American)',
    'ES - Spanish',
    'ET - Estonian',
    'FI - Finnish',
    'FR - French',
    'HU - Hungarian',
    'ID - Indonesian',
    'IT - Italian',
    'JA - Japanese',
    'LT - Lithuanian',
    'LV - Latvian',
    'NL - Dutch',
    'PL - Polish',
    'PT-BR - Portuguese (Brazilian)',
    'PT-PT - Portuguese',
    'RO - Romanian',
    'RU - Russian',
    'SK - Slovak',
    'SL - Slovenian',
    'SV - Swedish',
    'TR - Turkish',
    'UK - Ukrainian',
    'ZH - Chinese (simplified)',
]

const TranslationLanguagePicker = (props:{onLanguageSelect:(selectedLanguage:string)=>void}) => {
    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });

    const dispatch = useAppDispatch();

    const [saveToPreferences, setSaveToPreferences] = useState<boolean>(true);
    const [translationLanguage, updateTranslationLanguage] = useState<string | null>("");

    const onButtonClick = () => {
        if (translationLanguage) {
            if (saveToPreferences) {
                dispatch(setTranslationLanguage(translationLanguage));
            }
            props.onLanguageSelect(translationLanguage);
        }
    }

    return <PickerWrapper>
        <PickerWindow sx={{position:"absolute"}}>
            <ThemeProvider theme={darkTheme}>
                <Autocomplete
                    disablePortal
                    id="trasnlationLanguagePicker"
                    options={availableLanguageForTranslation}
                    sx={{ width: "80%" }}
                    onChange={(_, newValue)=> newValue ? updateTranslationLanguage(newValue) : updateTranslationLanguage("")}
                    renderInput={(params) => <TextField {...params} label="Translate to" />}
                />

                <FormGroup sx={{ width: "80%"}}>
                    <FormControlLabel sx={{span: {fontSize:"0.8em"}}} control={<Checkbox onChange={()=>setSaveToPreferences(!saveToPreferences)} checked={saveToPreferences} />} label="Don't ask me anymore" />
                </FormGroup>
                <Button sx={{m:1}} variant={"outlined"} onClick={()=>onButtonClick()}> Select Language </Button>
            </ThemeProvider>

            <small>Translations may not be 100% correct. They are computer generated.</small>
    </PickerWindow>
    </PickerWrapper>
}

export default TranslationLanguagePicker
