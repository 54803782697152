import styled from "@emotion/styled";
import {getUrlFromAsset, LanguageText, useMongoDB, UserCustomData, useRealmApp} from "@pal/common";
import { useEffect, useRef, useState } from "react";
import PauseIcon from "@mui/icons-material/Pause";
import TranslateIcon from '@mui/icons-material/Translate';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { ButtonIcon } from "../layout/ButtonIcon";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { keyframes } from "@emotion/react";
import { merge, lightSpeedIn, bounceInRight, fadeIn } from "react-animations";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import { getTranslationLanguage, setVoiceAudioSrc} from "../../slices/userGamePlaySlice";
import ReactMarkdown from "react-markdown";
import TranslationLanguagePicker from "./TranslationLanguagePicker";
import {useUserDataService} from "../../services/UserDataService";
import {getGenderMessage} from "../../screens/GameLocationStage";

const speedInBounce = merge(lightSpeedIn, bounceInRight);
const messagePAnimation = keyframes`${speedInBounce}`;

const messageHintAnimation = keyframes`${fadeIn}`;

const MessageP = styled.p`
  animation: 1s ${messagePAnimation};

  p { //ReactMarkdown P
    justify-content: center;
    padding: 15px 35px;
    text-align: center;
    font-size: 1.2em;
    font-weight: 500;
  }
`;

export const MessageDisplayWrapper = styled.div`
  margin-top: -40px;

  position: relative;
`;

export const MessageDisplayBox = styled.div<{
  $color?: string;
  $color2?: string;
  $bigVariant: boolean;
}>`
  background: linear-gradient(
    180deg,
    #fff4bb 50%,
    rgba(255, 244, 187, 0.9) 100%
  );
  ${(props) =>
    props.$color &&
    "background:linear-gradient(180deg," +
      props.$color +
      " 50%, "+ (props.$color2 ? props.$color2 : "rgba(255, 244, 187, 0.9)") + " 100%)"};
  height: fit-content;/*${(props) => (!props.$bigVariant ? "fit-content" : "15em")};*/
  z-index: 1;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  clip-path: polygon(1.20% 0.70%, 100% 5.77%, 95.09% 100%, 4.34% 100%)

;
`;

export const ActionBar = styled.div`
  position: absolute;
  bottom: -20px;
  left: 20px;
  right: 20px;
  padding: 0px 10px;

  Button {
    border-radius: 100% !important;
    width: 40px;
    height: 40px;
    font-size: 2em;
    box-shadow: 0px -2px 4px rgb(148, 113, 148);

    &:hover {
      box-shadow: 0px -2px 4px rgb(148, 113, 148);
    }
  }
`;

const TranslationText = styled.div`
  text-align: center;
  margin-top: -30px;
  margin-bottom: 30px;
  padding: 0px 30px;
  animation: 0.6s ${messageHintAnimation};
`;

const HintTextContainer = styled.div`
  width: 90%;
  background: rgb(241, 223, 145);
  background: linear-gradient(0deg,
  rgba(241, 223, 145, 1) 0%,
  rgb(244, 252, 185) 100%);
  color: #1f0068;
  margin: 20px auto 0px;
  border-radius: 20px;
  text-align: center;
  animation: 0.6s ${messageHintAnimation};

  padding: 10px 20px;

  p {
    padding: 0;
    margin: 0;
  }
`;

export const HintText = (props) => {
  return <HintTextContainer><ReactMarkdown>{props.children}</ReactMarkdown></HintTextContainer>
}

const MessageDisplay = (props: {
  children?: React.ReactNode;
  message?: LanguageText;
  showNext: boolean;
  onNext: () => void;
  color?: string;
  color2?:string;
  bigVariant?: boolean;
}) => {
  const { children, message, showNext, onNext, color, color2, bigVariant } = props;

  const enum AudioStates {
    idle,
    play,
    paused,
    finished,
  }
  const [audioState, setAudioState] = useState<AudioStates>(AudioStates.idle);
  const [hintText, setHintText] = useState<string>("");
  const [translatedText, setTranslatedText] = useState<string>("");
  const userDataService = useUserDataService();
  const userCustomData = userDataService.getCustomData();
  const [showTranslationLanguageWindow, setShowTranslationLanguageWindow] = useState<boolean>(false);

  const messageText = getGenderMessage(message, userCustomData?.gender);

  const [audioSrc, setAudioSrc] = useState<string>("");
  const audioRef = useRef(new Audio(audioSrc));
  const dispatch = useAppDispatch();
  const translationLanguage = useAppSelector(getTranslationLanguage);

  // @ts-ignore
  const { requestTextTranslation } = useRealmApp();

  useEffect(() => {
    const newAudioSrc = userCustomData?.gender === 0 && message?.faudio ? getUrlFromAsset(message.faudio) : message?.audio && getUrlFromAsset(message?.audio) || "";
    if (newAudioSrc !== "") {
      dispatch(setVoiceAudioSrc(newAudioSrc));
    }
    audioRef.current?.pause();
    audioRef.current?.remove();
    audioRef.current = new Audio(newAudioSrc);
    setAudioSrc(newAudioSrc);
    setAudioState(AudioStates.idle);
    setHintText("");
  }, [message]);

  useEffect(() => {
    if (audioRef && audioRef.current) {
      const onEnded = () => {
        setAudioState(AudioStates.finished);
      };
      audioRef.current.addEventListener("ended", onEnded);

      return () => {
        audioRef.current.pause();
        audioRef.current.remove();
        audioRef.current.removeEventListener("ended", onEnded);
      };
    }
  }, [audioRef]);

  useEffect(() => {
    switch (audioState) {
      case AudioStates.play:
        audioRef.current?.play();
        break;
      case AudioStates.paused:
        audioRef.current?.pause();
        break;
    }
  }, [audioState]);

  const onPlayClick = () => {
    if (audioRef && audioRef.current)
      switch (audioState) {
        case AudioStates.play:
          setAudioState(AudioStates.paused);
          break;
        case AudioStates.paused:
        case AudioStates.idle:
        case AudioStates.finished:
          setAudioState(AudioStates.play);
          break;
      }
  };

  const onHintClick = () => {
    setHintText(message?.hint || "Sorry, there's no hint this time.");
  };

  const onTranslateClick = async () => {
    if (translatedText) {
      setTranslatedText("");
      return;
    }
    if (!translationLanguage || translationLanguage === "") {
      setShowTranslationLanguageWindow(true);
    } else  {
      const translation = await requestTextTranslation(messageText, translationLanguage.split(' - ')[0]);
      setTranslatedText(translation.translatedText)
    }
  };

  const onLanguageSelect = async (selectedLanguage:string) => {
    const translation = await requestTextTranslation(messageText, selectedLanguage.split(' - ')[0]);
    setTranslatedText(translation.translatedText)
    setShowTranslationLanguageWindow(false);
  }

  const getAudioIcon = () => {
    switch (audioState) {
      case AudioStates.play:
        return <PauseIcon />;
      case AudioStates.paused:
      case AudioStates.idle:
      case AudioStates.finished:
        return <PlayArrowIcon />;
      default:
        return <></>;
    }
  };
  const audioIcon = getAudioIcon();

  return (
    <>
      <MessageDisplayWrapper>
        {showTranslationLanguageWindow && <TranslationLanguagePicker onLanguageSelect={onLanguageSelect} />}
        <MessageDisplayBox $color={color} $color2={color2} $bigVariant={bigVariant || false}>
          {children}

          <MessageP key={messageText}><ReactMarkdown>{messageText}</ReactMarkdown></MessageP>
          {translatedText && (
              <TranslationText><ReactMarkdown>{translatedText}</ReactMarkdown></TranslationText>
          )}
        </MessageDisplayBox>

        <ActionBar>
          {message?.hint && <ButtonIcon variant="contained" onClick={() => !hintText ? onHintClick() : setHintText("")} sx={{border:hintText ? '3px #ff841b solid' :'0px' }}>
            ?
          </ButtonIcon>}
          {audioSrc && audioSrc !== "" && (
            <ButtonIcon variant="contained" onClick={() => onPlayClick()}>
              {audioIcon}
            </ButtonIcon>
          )}
          <ButtonIcon variant="contained" onClick={() => onTranslateClick()} sx={{border:translatedText ? '3px #ff841b solid' :'0px' }}>
            <TranslateIcon />
          </ButtonIcon>
          {showNext && (
            <div style={{ float: "right" }}>
              <ButtonIcon onClick={() => onNext()} variant="contained">
                <NavigateNextIcon />
              </ButtonIcon>
            </div>
          )}
        </ActionBar>
      </MessageDisplayWrapper>
      {hintText && <HintText>{hintText}</HintText>}
    </>
  );
};

export default MessageDisplay;
