import styled from "@emotion/styled";
import PublicIcon from "@mui/icons-material/Public";
import HikingIcon from "@mui/icons-material/Hiking";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { ButtonIcon } from "./ButtonIcon";
import { useHistory } from "react-router-dom";
import UndoIcon from "@mui/icons-material/Undo";
import { useAppDispatch } from "../../app/hooks";
import QuestManager from "./QuestManager";
import {purgeUserGameplayData} from "../../slices/userGamePlaySlice";
import {Box, Popover, Tooltip, Typography} from "@mui/material";
import {useState} from "react";
import {useTranslation} from "react-i18next";


const Wrapper = styled.nav`
  margin: auto;
  width: 100%;
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: space-evenly;
  z-index: 20;
  pointer-events: none;
  flex-direction: column;
`;

const MainMenuWrapper = styled.nav`
  margin: auto;
  width: 90%;
  max-width: 400px;
  display: flex;
  min-height: 80px;
  align-items: center;
  justify-content: space-evenly;
  z-index: 20;
  pointer-events: all;
  
`;

const ButtonWrapper = styled.div`
  width: fit-content;
  display: flex;
  flex-flow: column;
  text-align: center;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
  overflow: visible;
  position: relative;
  &:hover {
    transform: scale(1.1) !important;
  }

  ButtonIcon {
    width: fit-content;
    margin: auto;
  }
`;

export const NavigationButton = (props: {
  label: string;
  iconComponent: React.ReactNode;
  onClick?: () => void;
  showArrow?:boolean;
}) => {
  const { label, iconComponent, onClick, showArrow } = props;
  const Arrow = styled(Box)`
    position: absolute;
    width: 50px;
    height: 50px;
    top: -50px;
    background: rgb(2,0,36);
    background: linear-gradient(0deg, rgba(2,0,36,0) 0%, rgb(72, 18, 109) 56%);
    clip-path: polygon(50% 0%, 100% 50%, 65% 30%, 75% 100%, 25% 100%, 35% 29%, 0 50%);
  `
  return (
    <ButtonWrapper>
      {showArrow && <Arrow />}
      <ButtonIcon onClick={onClick} variant="contained" sx={{clipPath: "polygon(1% 5%, 100% 0%, 89% 92%, 11% 95%)"}}>
        {iconComponent}
      </ButtonIcon>
      <span>{label}</span>
    </ButtonWrapper>
  );
};

const NavigationBar = (props: {
  onQuestWidgetClick?: ()=>void;
  showNavToMainMenu?: boolean;
}) => {
  const { showNavToMainMenu } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [showComingSoonPopover, setShowComingSoonPopover] = useState<boolean>(false);
  const { t } = useTranslation();

  const pushToMainMenu = () => {
    //dispatch(purgeQuestTracks());
    history.push("/");
  };
  const pushToPage = (page: string) => history.push("/" + page);

  return (
    <Wrapper>
      <QuestManager onQuestWidgetClick={props.onQuestWidgetClick} />
      <MainMenuWrapper>
          {showNavToMainMenu && (
            <NavigationButton
              onClick={pushToMainMenu}
              label={t("nav.home")}
              iconComponent={<PublicIcon fontSize="large" />}
            />
          )}

          <Popover
              open={showComingSoonPopover}
              onClick={()=>setShowComingSoonPopover(!showComingSoonPopover)}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
              }}
              transformOrigin={{
                  vertical: 'center',
                  horizontal: 'center',
              }}
          >
              <Typography sx={{ p: 2 }}>{t('home.comingSoon')}</Typography>
          </Popover>
          <NavigationButton
              label={t("nav.skills")}
            onClick={()=>setShowComingSoonPopover(!showComingSoonPopover) /*() => pushToPage("skills")*/}
            showArrow={false}
            iconComponent={<HikingIcon fontSize="large" />}
          />

          <NavigationButton
              label={t("nav.achievements")}
            onClick={()=>setShowComingSoonPopover(!showComingSoonPopover) /*() => pushToPage("achievements")*/}
            iconComponent={<EmojiEventsOutlinedIcon fontSize="large" />}
          />
          <NavigationButton
              label={t("nav.practice")}
            onClick={()=>setShowComingSoonPopover(!showComingSoonPopover) /*() => pushToPage("practice")*/}
            iconComponent={<FitnessCenterIcon fontSize="large" />}
          />
      </MainMenuWrapper>
    </Wrapper>
  );
};

export default NavigationBar;
