import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { persistor, store } from "./app/store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { MongoDB, RealmApp } from "@pal/common";
import { PersistGate } from "redux-persist/integration/react";
import { UserDataService } from "./services/UserDataService";
import {CssBaseline} from "@mui/material";
import './i18n/i18n';
import {QuestService} from "./services/QuestService";
import {AudioService} from "./services/AudioService";

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <RealmApp>
          <MongoDB>
            <UserDataService>
              <QuestService>
                <AudioService>
                  <App />
                </AudioService>
              </QuestService>
            </UserDataService>
          </MongoDB>
        </RealmApp>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
