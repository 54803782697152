import styled from "@emotion/styled";
import React, {useEffect, useState} from "react";
import {UserCustomDataDAL, useRealmApp} from "@pal/common";
import {Box, CircularProgress, FormControl, FormControlLabel, Radio, RadioGroup, Tooltip,} from "@mui/material";
import {PasswordInput} from "./PasswordInput";
import {useHistory} from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import {AuthFlowInput, AuthWrapper, ErrorContainer, MainActionsWrapper, MyButton, MyLink} from "./AuthComponents";
import {genderEnum, UserCustomData} from "../../model/User";
import {Label} from "@mui/icons-material";
import {setUserCustomData} from "@pal/gameplayer/src/slices/gameSystemSlice";

export interface MyInputProps {
    id: string,
    label: string,
    value: string,
    onChange: React.ChangeEventHandler<HTMLInputElement>,
    onInputKeyDown?: React.KeyboardEventHandler<HTMLInputElement | HTMLTextAreaElement>
}

export const ColoredInfoIcon = styled(InfoIcon)`
  color: orange;
`
export const validateEmail = (email:string) => {
    const regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return(regexp.test(email))
}

export function LoadingProcess (props: any) {
    const title = props.title;

    const AbsoluteWrapper = styled.div`
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: 1000;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      background: #00000033;
    `
    // @ts-ignore
    const LoadingBox = styled(Box)`
      width: 300px;
      height: 200px;
      background: white;
      border-radius: 20px;
      padding: 20px;
      background: #e78132;
      color: white;
      svg {
        color: white;
      }
    `
    return <AbsoluteWrapper>
            <LoadingBox>
                <h2>{title}</h2>
                <CircularProgress />
            </LoadingBox>
        </AbsoluteWrapper>;
}

const LoginForm = (props: {enableSignUp?: boolean, signUpIntent?: boolean, signUpCallback?: (UserCustomData)=>void }) => {
    const {logIn, signUp} = useRealmApp();
    const { user } = useRealmApp();
    const authed = (user && user.state === "active");
    const {enableSignUp, signUpIntent} = props;

    const [userBasicData, setUserBasicData] = useState<UserCustomData>({
        _id: "",
        userId: "",
        name: "",
        email: "",
        gender: genderEnum.she,
    });
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [showSignUp, setShowSignUp] = useState(enableSignUp && signUpIntent)
    const [isLoading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null)


    useEffect(() => {
        if (user && authed) {
            window.location.href = "/";
        }
    }, [user, authed])


    const handleLoginSignUpSwitch = async () => {
        if (!enableSignUp) {
            setShowSignUp(false);
        } else {
            setShowSignUp(!showSignUp);
        }
    }

    const handleLogin = async () => {
        const response = await logIn(email.toLowerCase(), password);
        if (!response) setError("login failed");
    }

    const handleSignUp = async () => {
        setError("");
        if (!validateEmail(email)) {
            setError("Invalid email");
            return
        }
        if (password !== "" && password === confirmPassword) {
            setLoading(true);
            const response = await signUp(email.toLowerCase(), password);
            setLoading(false);
            if (response?.errorMessage) {
                setError(response?.errorMessage);
            } else {
                await handleLogin();
                if(props.signUpCallback) {
                    props.signUpCallback(Object.assign(userBasicData, {email: email.toLowerCase()}));
                }
            }
        } else {
            setError("invalid password or passwords don't match");
        }
    }

    const onInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !showSignUp) {
            handleLogin()
        }
    }

    const updateUserBasicData = (changes) => {
        let userDataClone = {...userBasicData};
        setUserBasicData(Object.assign(userDataClone, changes));
    }
    const buildSignUpExtraFields = () => {
        const HeaderTitles = styled.h4`
          margin-bottom: 0;
          text-align: left;
          padding-left: 25px;
          font-size: 1.2em;
        `
        return <>

            <PasswordInput id={"confirmPassword"} label={"Confirm password"} value={confirmPassword}
                           onChange={(event) => setConfirmPassword(event.target.value)}/>
            <HeaderTitles>How would you like to be addressed in Play a Language?</HeaderTitles>
            <FormControl>


                <RadioGroup
                    aria-labelledby="genderLabel"
                    name="radio-buttons-group"
                    onChange={(e) => updateUserBasicData({gender: e.target.value})}
                >

                    <div style={{
                        display: "flex",
                        alignContent: "center",
                        alignItems: "center",
                        justifyContent: "left",
                        padding: "0px 25px"
                    }}>

                        <FormControlLabel value={genderEnum.she} control={<Radio/>} label="She"/>
                        <FormControlLabel value={genderEnum.he} control={<Radio/>} label="He"/>
                        <FormControlLabel value={genderEnum.they} control={<Radio/>} label="They"/>
                        <Tooltip
                            title="Please be aware that some languages, like spanish, don't have gender neutral pronouns.">
                            <ColoredInfoIcon/>
                        </Tooltip>
                    </div>
                </RadioGroup>
                <AuthFlowInput id={"userChosenName"} label={"First name"} value={userBasicData.name}
                               onChange={(e) => updateUserBasicData({name: e.target.value})}/>
            </FormControl>
            <p style={{padding: "0px 5%"}}>
                By registering, I accept the <a href="https://www.playalanguage.com/general-terms-of-use"
                                                target="_blank">General Terms and Conditions</a> and the <a
                href="https://www.playalanguage.com/privacy-policy" target="_blank">Privacy Policy</a>. I agree to receive
                product-related emails, which can be unsubscribed at any time.
            </p>
        </>
    }
    const signUpExtraFields = buildSignUpExtraFields();

    return  <AuthWrapper title={showSignUp && enableSignUp? "Sign Up!" : "Login"} gameApp={enableSignUp}>
            {error && <ErrorContainer>{error}</ErrorContainer>}
            {isLoading && <LoadingProcess title={'We\'re preparing some cool stuff for you!'}/>}
            <AuthFlowInput id="email" label="Email" value={email}
                           onChange={(event) => setEmail(event.target.value)}/>

            <PasswordInput id={"password"} label={"Password"} value={password}
                           onChange={(event) => setPassword(event.target.value)}
                           onInputKeyDown={(event) => onInputKeyDown(event)}/>

            {showSignUp ? signUpExtraFields : <MyLink href={"/forgot-password"}>Forgot password?</MyLink>}
            <MainActionsWrapper>
                {showSignUp ? <>
                        <MyButton variant="contained" onClick={handleSignUp}>Sign up</MyButton>
                        <MyLink onClick={handleLoginSignUpSwitch}>I have an account</MyLink>
                    </> :
                    <>
                        <MyButton variant="contained" onClick={handleLogin}>login</MyButton>
                        {enableSignUp && <MyLink onClick={handleLoginSignUpSwitch}>I don't have an account</MyLink>}
                    </>}
            </MainActionsWrapper>

    </AuthWrapper>
}

export default LoginForm;
