import React, {useContext, useEffect, useRef, useState} from "react";
import {
  LanguageLevel,
  LearningLanguage,
  useMongoDB,
  UserCustomData,
  UserCustomDataDAL,
  useRealmApp,
  UserGameplayData,
  UserGameplayDataDAL,
  UserLanguageGameplayData,
} from "@pal/common";
import {
  getLastGameplayDataRefresh,
  getUserCustomData, setLastGameplayDataRefresh,
  setUserCustomData,
} from "../slices/gameSystemSlice";
import {useAppDispatch, useAppSelector} from "../app/hooks";
import {
  getUnsavedChanges,
  getUserGameplayData, getUserIdFromGameplayData, setChangesSaved,
  setUserGameplayData,
  setUserGameplayDataLanguage
} from "../slices/userGamePlaySlice";

export interface IUserDataService {
  setupData: (customData: UserCustomData) => Promise<boolean>;
  getGameplayData: () => UserGameplayData | undefined; //Promise<UserGameplayData | undefined>;
  getCustomData: () => UserCustomData | undefined;

  getLanguageGameplayData: (
    learningLanguage: LearningLanguage
  ) => UserLanguageGameplayData | undefined;
  setLanguageLevel: (
    language: LearningLanguage,
    level: LanguageLevel
  ) => Promise<boolean>;

  syncUserData: () => void;
}
const UserDataContext = React.createContext<IUserDataService | undefined>(
  undefined
);

export const UserDataService = ({ children }) => {
  const dispatch = useAppDispatch();
  const { user } = useRealmApp();
  const { userDB } = useMongoDB();
  const userGameplayData = useAppSelector(getUserGameplayData);
  const userGameplayDataRef = useRef<UserGameplayData>();

  useEffect(() => {
    userGameplayDataRef.current = userGameplayData!;
  }, [userGameplayData]);

  const userIdFromGameplayData = useAppSelector(getUserIdFromGameplayData);
  const userCustomData = useAppSelector(getUserCustomData);

  const unSavedChanges = useAppSelector(getUnsavedChanges);
  const unSavedChangesRef = useRef<boolean>();

  useEffect(() => {
    unSavedChangesRef.current = unSavedChanges;
  }, [unSavedChanges]);

  const lastGameplayDataRefresh = useAppSelector(getLastGameplayDataRefresh);
  const lastDataRefreshRef = useRef<number>();

  useEffect(() => {
    lastDataRefreshRef.current = lastGameplayDataRefresh!;
  }, [lastGameplayDataRefresh]);

  const [loadingData, setLoadingData] = useState<boolean>(false);

  useEffect(() => {
    if (userIdFromGameplayData && userIdFromGameplayData !== '') {
      const interval = setInterval(() => syncUserData(), 60 * 1000); //sync every 60 seconds
      return () => {
        if (interval) {
          clearInterval(interval);
        }
      };
    }
  }, [userIdFromGameplayData]);

  //This useEffect is responsible for the first data loading
  useEffect(() => {
    if ((!userIdFromGameplayData || userIdFromGameplayData === "") && user && userDB && !loadingData) {
        console.log("should load data")
        loadGameplayData().then(() => {
          setLoadingData(false)
          dispatch(setLastGameplayDataRefresh(Date.now()))
        });
    }
  }, [userIdFromGameplayData, user, userDB]);

  //This useEffect is responsible for the first loading of custom data
  useEffect(() => {
    if ((!userCustomData || userCustomData?.userId === "" || userIdFromGameplayData === "") && user && userDB) {
      loadCustomData().then(() => {
          setLoadingData(false)
        });
      }
  }, [userCustomData, user, userDB]);

  //users refs because it's run on setInterval
  const syncUserData = async () => {

    //forces refresh if last refresh is too old.
    if (!lastDataRefreshRef.current || Date.now() - lastDataRefreshRef.current >= 5 * 60 * 1000) {
      loadGameplayData().then(() => {
        dispatch(setLastGameplayDataRefresh(Date.now()))
      });
    }

    if (userIdFromGameplayData !== "" && userGameplayDataRef.current) {
      if (unSavedChangesRef.current) {
        const data = await UserGameplayDataDAL.save(userGameplayDataRef.current);
        if (data?.modifiedCount > 0) {
          dispatch(setChangesSaved())
          dispatch(setLastGameplayDataRefresh(Date.now()))
          console.log("sync dat", data);
        } else {
          console.log("sync problem", data);
        }
      } else if (!loadingData) {
        console.log("reload data from server");
        loadGameplayData().then(() => {
          dispatch(setLastGameplayDataRefresh(Date.now()))
          setLoadingData(false)
        });
      }
    }
  };

  const loadGameplayData = async () => {
    try {
      setLoadingData(true);
      const data = await fetchGameplayData();
      if (!data) {
        await initGameplayData();
      } else {
        dispatch(setUserGameplayData(data));
      }
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  };

  const loadCustomData = async () => {
    try {
      const data = await fetchCustomData();
      dispatch(setUserCustomData(data));
    } catch (error) {
      console.error(error);
      return false;
    }
    return true;
  };

  const initGameplayData = async () => {
    try {
      if (user) {
        const initGamePlayData: UserGameplayData = {
          _id: "",
          userId: user.id,
          languageSpecific: [],
        };
        const responseGameplayData = await UserGameplayDataDAL.save(
          initGamePlayData
        );
        const savedGPData = Object.assign(initGamePlayData, {
          _id: responseGameplayData.insertedId.toString(),
        });
        dispatch(setUserGameplayData(savedGPData));
      }
    } catch (error) {
      return false;
    }
    return true;
  };
  const fetchGameplayData = async () => {
    try {
      const data = await UserGameplayDataDAL.fetch(user!.id, "userId");
      return data.length && data[0] || undefined;
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const fetchCustomData = async () => {
    try {
      const userCustomData = await UserCustomDataDAL.fetch(user!.id, "userId");
      if (userCustomData.length){
        const d = userCustomData[0];
        return {
          userId: d.userId,
          email: d.email,
          name: d.email,
          gender:  parseInt(d.gender.toString())
        }
      }
      return undefined;
    } catch (error) {
      console.error(error);
    }
    return null;
  };

  const getGameplayData = () => {
    return userGameplayData;
  };

  const getCustomData = () => {
    return userCustomData;
  };

  const getLanguageGameplayData = (language: LearningLanguage) => {
    return (
      userGameplayData?.languageSpecific?.filter(
        (item) => item.language === language
      )[0]?.userLanguageGameplayData || undefined
    );
  };

  const setLanguageLevel = async (
    language: LearningLanguage,
    level: LanguageLevel
  ) => {
    let userLanguageGameplayData: UserLanguageGameplayData;
    // @ts-ignore
    userLanguageGameplayData = {
      ...userGameplayData?.languageSpecific?.filter(
        (item) => item.language === language
      )[0]?.userLanguageGameplayData,
    } || {
      language: language,
      languageLevel: level,
    };
    userLanguageGameplayData.language = language;
    userLanguageGameplayData.languageLevel = level;

    dispatch(setUserGameplayDataLanguage(userLanguageGameplayData));
    return true;
  };

  const setupData = async (customData: UserCustomData) => {
    try {
      const response = await UserCustomDataDAL.save(customData);
      const savedCustomData = Object.assign(customData, {
        _id: response.insertedId.toString(),
      });
      dispatch(setUserCustomData(savedCustomData));
      await initGameplayData();
    } catch {
      return false;
    }

    return true;
  };

  return (
    <UserDataContext.Provider
      value={{
        setupData,
        setLanguageLevel,
        getGameplayData,
        getCustomData,
        getLanguageGameplayData,
        syncUserData
      }}
    >
      {children}
    </UserDataContext.Provider>
  );
};

export const useUserDataService = () => {
  const context = useContext(UserDataContext);
  if (process.env.NODE_ENV === 'test') {
    //mock
    return {
      getLanguageGameplayData: (language):UserLanguageGameplayData =>  {
        const languageLevel: LanguageLevel = 'intermediate'
        return {
          language: language,
          languageLevel: languageLevel
        }
      },
      setLanguageLevel: (language: LearningLanguage, level: LanguageLevel) => {
        return new Promise<boolean>((resolve) => {
        });
      },
      getCustomData: () => {
        return{
          userId: "MockedId",
          name: "MockedUser",
          email: "mocked@email.com",
          gender: 0,
        }}
    }
  }

  if (context == null ) {
    throw new Error("useUserDataService() called outside of a Provider?");
  }
  return context;
};
