import styled from "@emotion/styled";
import PersonIcon from "@mui/icons-material/Person";
import {ButtonIcon} from "./ButtonIcon";
import React, {useState} from "react";
import {useRealmApp} from "@pal/common";
import {
    Box,
    Button,
    createTheme, Link,
    ThemeProvider
} from "@mui/material";
import LanguageSwitch from "../common/LanguageSwitch";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {purgeQuestSharedData, purgeUserCustomData} from "../../slices/gameSystemSlice";
import {getPlayingCity, purgeQuestGameplayData} from "../../slices/userGamePlaySlice";
import {convertLanguageNameToLanguageCode} from "../../screens/GameLocationStage";
import {purgeUserGameplayData} from "../../slices/userGamePlaySlice";
import OriginalLanguageSwitch from "../common/OriginalLanguageSwitch";
import {useHistory} from "react-router-dom";
import {useTranslation} from "react-i18next";

const ProfileWidgetButton = styled(Button)`
  color: white;
  margin-top: 20px;
  border-color: white !important;

  span {
    color: white;
  }
`;
const ProfileIcon = styled(PersonIcon)`
  width: 1.5em !important;
  height: 1.5em !important;
`;

type ProfileMenuProps = {
    open: boolean;
};

const ProfileMenu = styled.div<ProfileMenuProps>`
  background: ${(props) => props.theme.palette.primary.main};
  background: #462886;
  background: linear-gradient(180deg, #481579 0%, rgba(111, 75, 208, 1) 100%);
  color: white;
  position: absolute;
  top: 44px;
  border-radius: 20px 0px 20px 20px;

  right: 5px;
  width: 242px;
  height: ${(props) => (props.open ? "500px" : "0px")};
  overflow: hidden;
  transition: all 2.2s ease-in-out;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: flex-start;
  z-index: 20;

  h4 {
  }
`;




const ProfileMenuWidget = () => {
    const {logOut, user} = useRealmApp();
    const history = useHistory();
    // @ts-ignore
    const loggedEmail = user?._profile?.data?.email || "no@email.no";
    const playingCity = useAppSelector(getPlayingCity);
    const learningLanguage = playingCity ? convertLanguageNameToLanguageCode(playingCity.language) : undefined;
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const darkTheme = createTheme({
        palette: {
            mode: 'dark',
        },
    });
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    return (
        <div style={{position: "relative", pointerEvents: "all"}}>
            <ButtonIcon
                variant="contained"
                size="large"
                style={{pointerEvents: "all"}}
                onClick={() => {
                    setOpenMenu(!openMenu);
                }}
            >
                <ProfileIcon/>
            </ButtonIcon>
            {openMenu && (
                <ProfileMenu open={openMenu}>
                    <h4>{loggedEmail}</h4>
                    <Box sx={{borderTop: "1px solid white", p:1, width:'90%'}}>
                        <Link href={"/welcome"} sx={{color:"white"}}>{t("userMenu.reviewTutorial")}</Link>
                    </Box>
                    <Box
                        sx={{
                            borderTop: "1px solid white",
                            borderBottom: "1px solid white",
                            width: "90%",
                            textAlign: "right",
                            p: 1,
                        }}
                    >
                        {learningLanguage && <LanguageSwitch language={learningLanguage}/>}
                    </Box>
                    <Box>
                        <ThemeProvider theme={darkTheme}>
                            <Box sx={{ textAlign: "left", my:1 }}>{t("userMenu.translationLanguage")}</Box>
                            <OriginalLanguageSwitch />
                        </ThemeProvider>
                    </Box>
                    <Box sx={{
                        borderTop: "1px solid white",
                        width: "90%",
                        textAlign: "center",
                        margin: "10px 0px",
                    }}>
                    <ProfileWidgetButton
                        variant={"outlined"}
                        sx={{width:"80%"}}
                        onClick={() => {
                            dispatch(purgeQuestGameplayData())
                            dispatch(purgeQuestSharedData())
                        }}
                    >
                        {t("userMenu.wipeData")}
                    </ProfileWidgetButton>
                    <ProfileWidgetButton
                        variant={"outlined"}
                        onClick={() => {
                            setOpenMenu(!openMenu);
                            dispatch(purgeUserGameplayData())
                            dispatch(purgeQuestSharedData())
                            dispatch(purgeUserCustomData())
                            history.push("/");
                            logOut();
                        }}
                    >
                        {t("userMenu.logout")}
                    </ProfileWidgetButton>
                    </Box>
                </ProfileMenu>
            )}
        </div>
    );
};

export default ProfileMenuWidget;
